import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_BRANCH_LOOK_UP_REQUEST,
  DELETE_BRANCH_LOOK_UP_REQUEST,
  FETCH_BRANCH_LOOK_UPS_REQUEST,
  UPDATE_BRANCH_LOOK_UP_REQUEST,
} from "constants/BranchLookUp";
import {
  createBranchLookUpFailure,
  createBranchLookUpSuccess,
  deleteBranchLookUpFailure,
  deleteBranchLookUpSuccess,
  fetchBranchLookUpsFailure,
  fetchBranchLookUpsSuccess,
  setBranchLookUpValidationErrors,
  updateBranchLookUpFailure,
  updateBranchLookUpSuccess,
} from "redux/actions";
import BranchLookUpService from "services/BranchLookUp";

export function* onFetchBranchLookUps() {
  yield takeLatest(FETCH_BRANCH_LOOK_UPS_REQUEST, function* () {
    try {
      const result = yield call(BranchLookUpService.index);
      if (result.status) {
        yield put(fetchBranchLookUpsSuccess(result.data));
      } else {
        yield put(fetchBranchLookUpsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchBranchLookUpsFailure(error.message));
    }
  });
}

export function* onUpdateBranchLookUp() {
  yield takeLatest(UPDATE_BRANCH_LOOK_UP_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        BranchLookUpService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateBranchLookUpSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setBranchLookUpValidationErrors(result.data.errors));
        yield put(updateBranchLookUpFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateBranchLookUpFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateBranchLookUpFailure(error.message));
    }
  });
}

export function* onDeleteBranchLookUp() {
  yield takeLatest(DELETE_BRANCH_LOOK_UP_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(BranchLookUpService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteBranchLookUpSuccess(result.data));
      } else {
        yield put(deleteBranchLookUpFailure(result.message));
      }
    } catch (error) {
      yield put(deleteBranchLookUpFailure(error.message));
    }
  });
}

export function* onCreateBranchLookUp() {
  yield takeLatest(CREATE_BRANCH_LOOK_UP_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(BranchLookUpService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createBranchLookUpSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setBranchLookUpValidationErrors(result.data.errors));
        yield put(createBranchLookUpFailure(result.message));
      } else {
        yield put(createBranchLookUpFailure(result.message));
      }
    } catch (error) {
      yield put(deleteBranchLookUpFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchBranchLookUps),
    fork(onUpdateBranchLookUp),
    fork(onDeleteBranchLookUp),
    fork(onCreateBranchLookUp),
  ]);
}
