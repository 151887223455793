import {
    FETCH_DISCONTINUED_TEST_DIRECTORIES_FAILURE,
    FETCH_DISCONTINUED_TEST_DIRECTORIES_REQUEST,
    FETCH_DISCONTINUED_TEST_DIRECTORIES_SUCCESS,
  } from "constants/DiscontinuedTestDirectories";
  
  export const fetchDiscontinuedTestDirectoriesRequest = () => ({
    type: FETCH_DISCONTINUED_TEST_DIRECTORIES_REQUEST,
  });
  
  export const fetchDiscontinuedTestDirectoriesSuccess = (testDirectories) => ({
    type: FETCH_DISCONTINUED_TEST_DIRECTORIES_SUCCESS,
    payload: testDirectories,
  });
  
  export const fetchDiscontinuedTestDirectoriesFailure = (error) => ({
    type: FETCH_DISCONTINUED_TEST_DIRECTORIES_FAILURE,
    payload: error,
  });
  