export const SHOW_ORDER_STATUS_EDIT_FORM = "SHOW_ORDER_STATUS_EDIT_FORM";

export const FETCH_DECLINED_ORDER_REQUEST = "FETCH_DECLINED_ORDER_REQUEST";
export const FETCH_DECLINED_ORDER_SUCCESS = "FETCH_DECLINED_ORDER_SUCCESS";
export const FETCH_DECLINED_ORDER_FAILURE = "FETCH_DECLINED_ORDER_FAILURE";

export const FETCH_DECLINED_ORDER_OBX_REQUEST = "FETCH_DECLINED_ORDER_OBX_REQUEST";
export const FETCH_DECLINED_ORDER_OBX_SUCCESS = "FETCH_DECLINED_ORDER_OBX_SUCCESS";
export const FETCH_DECLINED_ORDER_OBX_FAILURE = "FETCH_DECLINED_ORDER_OBX_FAILURE";

export const UPDATE_DECLINED_ORDER_REQUEST = "UPDATE_DECLINED_ORDER_REQUEST";
export const UPDATE_DECLINED_ORDER_SUCCESS = "UPDATE_DECLINED_ORDER_SUCCESS";
export const UPDATE_DECLINED_ORDER_FAILURE = "UPDATE_DECLINED_ORDER_FAILURE";

export const SHOW_DECLINED_ORDER_OBX_DIALOG = "SHOW_DECLINED_ORDER_OBX_DIALOG";