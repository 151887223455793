import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_PENDINGRESULTS_REQUEST } from "constants/IncompleteResults";
import { 
    fetchPendingResultFailure,
    fetchPendingResultSuccess
} from "redux/actions/IncompleteResults"

import PendingResultService from "services/IncompleteResults";

export function* onFetchPendingResults() {
  yield takeLatest(FETCH_PENDINGRESULTS_REQUEST, function* (payload) {
    try {
      const result = yield call(PendingResultService.get, payload.payload);
      if (result.status) {
        yield put(fetchPendingResultSuccess(result.data));
      } else {
        yield put(fetchPendingResultFailure(result.message));
      }
    } catch (error) {
      yield put(fetchPendingResultFailure(error.message));
    }
    // console.log('aaaa');
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchPendingResults)]);
}
