import fetch from "auth/FetchInterceptor";

const PendingResultService = {};

PendingResultService.get = function (payload) {
  return fetch({
    url:
      "/reports/patientresult-pending?" +
      "startdate=" +
      payload.startDate +
      "&" +
      "enddate=" +
      payload.endDate +
      "&" +
      "agency=" + payload.agency + "&department=null",
    method: "get",
  });
};

export default PendingResultService;
