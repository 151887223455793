import _ from "lodash";

export const addNewOrder = (state, action) => {
  let list = state.list;
  const savedNewOrder = action.payload;

  return [savedNewOrder, ...list];
};


export const updatedTestOrder = (state, action) => {
  let list = state.list;
  const updatedTestOrder = action.payload;

  const index = _.findIndex(list, ["id", updatedTestOrder.id]);
  list[index] = updatedTestOrder;

  return [...list];
};


export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name:key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
