import fetch from "auth/FetchInterceptor";

const DailyReleasedTests = {};

DailyReleasedTests.get = function (payload) {
  return fetch({
    url: "/reports/daily-released-tests?" + 
    'startdate=' + payload.startDate + 
    '&enddate=' + payload.endDate
  });
};

export default DailyReleasedTests;