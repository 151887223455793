import {
  FETCH_PATIENTTESTRESULTHISTORY_SUCCESS,
  FETCH_PATIENTTESTRESULTHISTORY_REQUEST,
  FETCH_PATIENTTESTRESULTHISTORY_FAILURE,
  FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
  FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS,
  FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE,
  UPDATE_GRAPH_DATA

} from "constants/PatientTestResultHistory";
import { getPatientObxGraphResult } from "utils/PatientTestResultHistory";

const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  showSearchForm:true,
  showNewForm:true,
  patientslist:[],
  patientFullname : "",
  graphDatas : {
    series : [],
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false
        },
        animations: {
          enabled: true
        }
      },
      yaxis: {
        labels : {
          show : false
        },
        title: {
          text: ''
        }
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: [5,5,4],
        curve: 'straight'
      },
      labels : [],
      title: {
        text: ''
      }
  }}
};

const PatientTestResultHistory = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PATIENTTESTRESULTHISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
        patientFullname : action.payload.patientFullName
      };
    case FETCH_PATIENTTESTRESULTHISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        graphDatas : getPatientObxGraphResult(action.payload, state.patientFullname)
      };
    case FETCH_PATIENTTESTRESULTHISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        // showSearchForm: !state.showSearchForm,
        // list:[]
      };

      // case   SHOW_NEW_ORDER_SEARCH_FORM:
      //   return {
      //     ...state,
      //     showSearchForm: !state.showSearchForm,
      //     list:[]
      //   };

      case FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS:
        return {
          ...state,
          loading: false,
          patientslist: action.payload,
        };
      case FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
      case UPDATE_GRAPH_DATA : {
         return {
           ...state,
           graphDatas : getPatientObxGraphResult(action.payload.patientResult, action.payload.patientFullName)
         }
      }

    default:
      return state;
  }
};

export default PatientTestResultHistory;
