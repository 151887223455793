import fetch from "auth/FetchInterceptor";

const DashboardReport = {};

DashboardReport.get = function (payload) {
  return fetch({
    url:
      "/reports/getAdminReport?" +
      "startdate=" + payload.startDate + 
      "&enddate=" + payload.endDate +
      "&siteName="+payload.site +
      "&agency=" + payload.agency + 
      "&dateDifference=" + payload.dateDifference,
    method: "get",
  });
};


export default DashboardReport;
