import fetch from "auth/FetchInterceptor";

const BranchLookUpService = {};

BranchLookUpService.index = function () {
  return fetch({
    url: "/branch-look-ups",
    method: "get",
  });
};

BranchLookUpService.create = function (data) {
  return fetch({
    url: "/branch-look-ups",
    method: "post",
    data,
  });
};

BranchLookUpService.update = function (id, data) {
  return fetch({
    url: `/branch-look-ups/${id}`,
    method: "put",
    data,
  });
};

BranchLookUpService.delete = function (id) {
  return fetch({
    url: `/branch-look-ups/${id}`,
    method: "delete",
  });
};

export default BranchLookUpService;
