import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_DAILY_RELEASED_TESTS_REQUEST } from "constants/DailyReleasedTests";
import {
    fetchDailyReleasedTestsSuccess,
    fetchDailyReleasedTestsFailure
} from "redux/actions/DailyReleasedTests";
import DailyReleasedTests from "services/DailyReleasedTests";

export function* onFetchDailyReleasedTests() {
  yield takeLatest(FETCH_DAILY_RELEASED_TESTS_REQUEST, function* (payload) {
    try {
      const result = yield call(DailyReleasedTests.get, payload.payload);
      if (result.status) {
        yield put(fetchDailyReleasedTestsSuccess(result.data));
      } else {
        message.error("Something went wrong");
        yield put(fetchDailyReleasedTestsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(fetchDailyReleasedTestsFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchDailyReleasedTests)]);
}
