import fetch from "auth/FetchInterceptor";

const DiscrepancyPending = {};

DiscrepancyPending.get = function (payload) {
  return fetch({
    url:
      "/reports/discrepancy-pending?" +
      "startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" +
      payload.department,
    method: "get",
  });
};

DiscrepancyPending.update = function (id, data) {
  return fetch({
    url: `/poly-tech-patient-pid/${id}`,
    method: "put",
    data,
  });
};

DiscrepancyPending.Discrepancyobx = function (payload) {
  return fetch({
    url: "/reports/discrepancy_obx?" + "sampleId=" + payload.batchUuid + "&batchUuid=" + payload.sampleId,
    method: "get",
  });
};
export default DiscrepancyPending;
