import {
  FETCH_REFERABLE_ORDERS_FAILURE,
  FETCH_REFERABLE_ORDERS_REQUEST,
  FETCH_REFERABLE_ORDERS_SUCCESS,
} from "constants/ReferableOrders";

export const fetchReferableOrdersRequest = (
  startDate,
  endDate,
  agency,
  department
) => {
  return {
    type: FETCH_REFERABLE_ORDERS_REQUEST,
    payload: { startDate, endDate, agency, department },
  };
};

export const fetchReferableOrdersSuccess = (payload) => {
  return {
    type: FETCH_REFERABLE_ORDERS_SUCCESS,
    payload: payload,
  };
};

export const fetchReferableOrdersFailure = (payload) => {
  return {
    type: FETCH_REFERABLE_ORDERS_FAILURE,
    payload: payload,
  };
};
