import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import moment from "moment";
import { initializeTheMomentStartOfWeek } from 'utils/DateTimeUtil'

export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const QC_PREFIX_PATH = "/qc";
export const AUTH_PREFIX_PATH = "/auth";
export const ADMIN_PREFIX_PATH = "/admin";
export const UPDATE_ACTION = "update";
export const NEW_ACTION = "new";
export const ROLE_SUPER_ADMIN = "super-admin";
export const ROLE_GUEST = "guest";
export const ROLE_QUALITY_CONTROL = "qc-admin";
export const APP_VERSION  = 1.1;
export const SCATTER_GRAPH = "scatter";
export const LINE_GRAPH = "line";
export const BAR_GRAPH = "bar";
export const MAX_GRAPH_TESTS = 10;

export const ICL_TELEGRAM_CONTACT = "https://t.me/ICLCustomerSupport";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: (localStorage.getItem("darkMode") === null || localStorage.getItem("darkMode") === "light")  ? "light" : localStorage.getItem("darkMode"),
  direction: DIR_LTR,
};
initializeTheMomentStartOfWeek();
export const INITIAL_DATE_TIME_AND_AGENCY_DEPARTMENT = {
  "startDate" :  moment().subtract(1, 'days').format("YYYYMMDD") + "000000",
  "endDate" :  moment().format("YYYYMMDD") + "999999",
  "agency" : null,
  "department" : null
}

export const DAILY_RELEASED_TESTS_DATE = {
  "startDate" :  moment().format("YYYYMMDD").toString() + "000000",
  "endDate" :  moment().format("YYYYMMDD").toString() + "999999",
  "agency" : null,
  "department" : null
}

export const COVID_FAMILIES_TESTS = ["COVTR"];

//the below expiration time is in hour
export const TOKEN_EXPIRATION_TIME = 1;

export const UNPROCESSABLE_ENTITY_CODE = 422;
export const PERMISSION_DENIED_CODE = 403;