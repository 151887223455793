import { CURRENT_USER } from "redux/constants/Auth";
export const getTheCurrentUserPermissions = () => {
  const currentUser = getParsedCurrentUser();
  //const currentUser = ["view-testDirectories", "view-neworders",  "view-pending-discrepancies", "approve-discrepancy", "decline-discrepancy"];
  return currentUser?.permissions;
};

export const DoesThisUserHasAPermissionToThisComponent = (component_permission) => {
  const user_permissions = getTheCurrentUserPermissions();
  const current_user_permission = user_permissions != undefined ? user_permissions.map( item =>{
     return item.permission_name;
  }) : [];
  return current_user_permission.length <= 0 ? false :  current_user_permission.includes(component_permission, 0);
};

export const getCurrentRoleName = () =>{
   return getParsedCurrentUser().name;
}

export const getCurrentRole = () =>{
  return getParsedCurrentUser().role;
}

function getParsedCurrentUser()
{
   return JSON.parse(localStorage.getItem(CURRENT_USER));
}

export const doesThisUserIsAgency = ()=>{
   let currentUser = getParsedCurrentUser();
   return currentUser["client_company"] != null ? true : false;
}

export const doesThisUserIsIclUser =() =>{
  if(!getParsedCurrentUser())
  {
     return 0;
  }
  else {
    return getParsedCurrentUser()['icl_user'] == 1 ? 1 : 0;
}
}
export const doesThisUserNotIclUser =() =>{
  return  doesThisUserIsIclUser() ? 0 : 1;
}
