import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_TEST_DIRECTORY_REQUEST,
  DELETE_TEST_DIRECTORY_REQUEST,
  FETCH_TEST_DIRECTORIES_REQUEST,
  UPDATE_TEST_DIRECTORY_REQUEST,
} from "constants/TestDirectory";
import {
  createTestDirectoyFailure,
  createTestDirectoySuccess,
  deleteTestDirectoyFailure,
  deleteTestDirectoySuccess,
  fetchTestDirectoriesFailure,
  fetchTestDirectoriesSuccess,
  setTestDirectoryValidationErrors,
  updateTestDirectoyFailure,
  updateTestDirectoySuccess,
} from "redux/actions";
import TestDirectoryService from "services/TestDirectoryService";

export function* onFetchTestDirectories() {
  yield takeLatest(FETCH_TEST_DIRECTORIES_REQUEST, function* () {
    try {
      const result = yield call(TestDirectoryService.index);
      if (result.status) {
        yield put(fetchTestDirectoriesSuccess(result.data));
      } else {
        yield put(fetchTestDirectoriesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchTestDirectoriesFailure(error.message));
    }
  });
}

export function* onUpdateTestDirectory() {
  yield takeLatest(UPDATE_TEST_DIRECTORY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        TestDirectoryService.update,
        payload.id,
        payload
      );
      
      if (result.status) {
        message.success("Updated");
        yield put(updateTestDirectoySuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setTestDirectoryValidationErrors(result.data.errors));
        yield put(updateTestDirectoyFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateTestDirectoyFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateTestDirectoyFailure(error.message));
    }
  });
}

export function* onDeleteTestDirectory() {
  yield takeLatest(DELETE_TEST_DIRECTORY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(TestDirectoryService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteTestDirectoySuccess(result.data));
      } else {
        message.error("Something went wrong");
        yield put(deleteTestDirectoyFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteTestDirectoyFailure(error.message));
    }
  });
}

export function* onCreateTestDirectory() {
  yield takeLatest(CREATE_TEST_DIRECTORY_REQUEST, function* ({ payload }) {
    try {
      console.log(payload);
      const result = yield call(TestDirectoryService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createTestDirectoySuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setTestDirectoryValidationErrors(result.data.errors));
        yield put(createTestDirectoyFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createTestDirectoyFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteTestDirectoyFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchTestDirectories),
    fork(onUpdateTestDirectory),
    fork(onDeleteTestDirectory),
    fork(onCreateTestDirectory),
  ]);
}
