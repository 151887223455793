import fetch from "auth/FetchInterceptor";

const SiteNamesService = {};

SiteNamesService.get = function () {
  return fetch({
    url:"/reports/getSiteNames",
    method: "get",
  });
};


export default SiteNamesService;
