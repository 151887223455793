import fetch from "auth/FetchInterceptor";

const DiscrepancyService = {};

DiscrepancyService.index = function (payload) {
  return fetch({
    url:
      "/reports/discrepancy-all?" +
      "startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" +
      payload.department,
    method: "get",
  });
};

DiscrepancyService.update = function (id, data) {
  return fetch({
    url: `/poly-tech-patient-pid/${id}`,
    method: "put",
    data,
  });
};

DiscrepancyService.Discrepancyobx = function (payload) {
  return fetch({
    url: "/reports/discrepancy_obx?" + "sampleId=" + payload.sampleId + "&batchUuid=" + payload.batchUuid,
    method: "get",
  });
};

export default DiscrepancyService;
