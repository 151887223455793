import fetch from "auth/FetchInterceptor";
import { doesThisUserIsIclUser } from "utils/CurrentUserUtil";

const FinancialsService = {};

FinancialsService.index = function (payload) {
  return fetch({
    url: "/reports/financial?" +
    "startdate=" +
    payload.startDate +
    "&enddate=" +
    payload.endDate +
    "&agency=" +
    payload.agency +
    "&department=" +
    payload.department ,
  method: "get",
  });
};


export default FinancialsService;
