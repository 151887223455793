import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { ICL_PAY_REQUEST } from "constants/IclPay";
import { iclPayFailure, iclPaySuccess } from "redux/actions/IclPay";
import IclPayService from "services/IclPayService";

export function* onIclPayRequest() {
  yield takeLatest(ICL_PAY_REQUEST, function* (payload) {
    try {
      const result = yield call(IclPayService.pay, payload.payload);
      if (result.status) {
         yield put(iclPaySuccess(JSON.parse(result.data)));
      } else {
        yield put(iclPayFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(iclPayFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onIclPayRequest)]);
}
