import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import {
     FETCH_REFERABLE_ORDERS_REQUEST
} from "constants/ReferableOrders";

import{
     fetchReferableOrdersSuccess,
     fetchReferableOrdersFailure
} from "../actions/ReferableOrders"

import ReferableOrdersService from "services/ReferableOrders";

export function* onFetchReferableOrders() {
  yield takeLatest(FETCH_REFERABLE_ORDERS_REQUEST, function* (payload) {
    try {
      const result = yield call(ReferableOrdersService.get, payload.payload);
      if (result.status) {
        yield put(fetchReferableOrdersSuccess(result.data));
      } else {
        yield put(fetchReferableOrdersFailure(result.message));
      }
    } catch (error) {
        message.error("something went wrong");
      yield put(fetchReferableOrdersFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchReferableOrders)]);
}
