import {
  FETCH_PATIENTRESULTS_SUCCESS,
  FETCH_PATIENTRESULTS_REQUEST,
  FETCH_PATIENTRESULTS_FAILURE,
  UPDATE_PATIENTRESULT_REQUEST,
  UPDATE_PATIENTRESULT_SUCCESS,
  UPDATE_PATIENTRESULT_FAILURE,
  SHOW_PATIENTRESULT_EDIT_FORM,
  DELETE_PATIENTRESULT_REQUEST,
  DELETE_PATIENTRESULT_SUCCESS,
  DELETE_PATIENTRESULT_FAILURE,
  SET_PATIENTRESULT_VALIDATION_ERRORS,
  SHOW_PATIENTRESULT_NEW_FORM,
  CREATE_PATIENTRESULT_REQUEST,
  CREATE_PATIENTRESULT_SUCCESS,
  CREATE_PATIENTRESULT_FAILURE,
  FETCH_PATIENT_HISTORY_REQUEST,
  FETCH_PATIENT_HISTORY_SUCCESS,
  FETCH_PATIENT_HISTORY_FAILURE,
  FETCH_DETAILED_PATIENT_RESULT_REQUEST,
  FETCH_DETAILED_PATIENT_RESULT_SUCCESS,
  FETCH_DETAILED_PATIENT_RESULT_FAILURE,
} from "constants/PatientResult";

import {changeDateToHumanReadableFormat} from "../../utils/DateTimeUtil"

const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  searching : false,
  searchedPatientList : []
};

const patientresult = (state = initState, action) => {
  switch (action.type) {
      case FETCH_PATIENTRESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
      case FETCH_PATIENTRESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: changeDateToHumanReadableFormat(action.payload),
      };

      case FETCH_PATIENTRESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
      
      case FETCH_PATIENT_HISTORY_REQUEST:
        return {
          ...state,
          searching: true,
          errorMessage: action.payload,
        };

      case FETCH_PATIENT_HISTORY_SUCCESS:
        return {
          ...state,
          searching: false,
          searchedPatientList: action.payload,
        };

      case FETCH_PATIENT_HISTORY_FAILURE:
        return {
          ...state,
          searching: false,
        };

        case FETCH_DETAILED_PATIENT_RESULT_REQUEST:
          return {
            ...state,
            searching: true,
          };
  
        case FETCH_DETAILED_PATIENT_RESULT_SUCCESS:
          return {
            ...state,
            searching: false,
            list: action.payload,
          };
  
          case FETCH_DETAILED_PATIENT_RESULT_FAILURE:
          return {
            ...state,
            searching: false,
          };

    default:
      return state;
  }
};

export default patientresult;
