import {
    FETCH_DISCONTINUED_TEST_DIRECTORIES_FAILURE,
    FETCH_DISCONTINUED_TEST_DIRECTORIES_REQUEST,
    FETCH_DISCONTINUED_TEST_DIRECTORIES_SUCCESS,
  } from "constants/DiscontinuedTestDirectories";
  
  
  const initState = {
    loading: false,
    list: [],
    errorMessage: "",
  };
  
  const discontinuedTestDirectory = (state = initState, action) => {
    switch (action.type) {
      case FETCH_DISCONTINUED_TEST_DIRECTORIES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_DISCONTINUED_TEST_DIRECTORIES_SUCCESS:
        return {
          ...state,
          loading: false,
          list: action.payload,
        };
      case FETCH_DISCONTINUED_TEST_DIRECTORIES_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
      default :  return state;
    }
  };
  
  export default discontinuedTestDirectory;
  