import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_EQUIPMENT_REQUEST,
  DELETE_EQUIPMENT_REQUEST,
  FETCH_EQUIPMENT_REQUEST,
  UPDATE_EQUIPMENT_REQUEST,
} from "constants/Equipment";
import {
  createEquipmentFailure,
  createEquipmentSuccess,
  deleteEquipmentFailure,
  deleteEquipmentSuccess,
  fetchEquipmentFailure,
  fetchEquipmentSuccess,
  setEquipmentValidationErrors,
  updateEquipmentFailure,
  updateEquipmentSuccess,
} from "redux/actions";
import EquipmentService from "services/Equipment";

export function* onFetchEquipment() {
  yield takeLatest(FETCH_EQUIPMENT_REQUEST, function* () {
    try {
      const result = yield call(EquipmentService.index);
      if (result.status) {
        yield put(fetchEquipmentSuccess(result.data));
      } else {
        yield put(fetchEquipmentFailure(result.message));
      }
    } catch (error) {
      yield put(fetchEquipmentFailure(error.message));
    }
  });
}

export function* onUpdateEquipment() {
  yield takeLatest(UPDATE_EQUIPMENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        EquipmentService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateEquipmentSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setEquipmentValidationErrors(result.data.errors));
        yield put(updateEquipmentFailure(result.message));
      } else {
        yield put(updateEquipmentFailure(result.message));
      }
    } catch (error) {
      yield put(updateEquipmentFailure(error.message));
    }
  });
}

export function* onDeleteEquipment() {
  yield takeLatest(DELETE_EQUIPMENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(EquipmentService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteEquipmentSuccess(result.data));
      } else {
        yield put(deleteEquipmentFailure(result.message));
      }
    } catch (error) {
      yield put(deleteEquipmentFailure(error.message));
    }
  });
}

export function* onCreateEquipment() {
  yield takeLatest(CREATE_EQUIPMENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(EquipmentService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createEquipmentSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setEquipmentValidationErrors(result.data.errors));
        yield put(createEquipmentFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createEquipmentFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteEquipmentFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchEquipment),
    fork(onUpdateEquipment),
    fork(onDeleteEquipment),
    fork(onCreateEquipment),
  ]);
}
