import { 
    FETCH_MICRO_BIOLOGY_RESULTS_REQUEST, 
    FETCH_MICRO_BIOLOGY_RESULTS_FAILURE,
    FETCH_MICRO_BIOLOGY_RESULTS_SUCCESS } from "constants/MicroBiology";

const initialValus = {
     list : [],
     loading : false,
}

const MicroBiology = (state = initialValus, action) =>{
 switch(action.type){
     case  FETCH_MICRO_BIOLOGY_RESULTS_REQUEST : 
      return {
        ...state,
        loading : true
      };
      case FETCH_MICRO_BIOLOGY_RESULTS_SUCCESS : 
      return {
         ...state,
         loading : false,
         list : action.payload
      };
      case FETCH_MICRO_BIOLOGY_RESULTS_FAILURE : 
      return {
         ...state,
         loading : false
      };
      default :
      return state
 }
}

export default MicroBiology;