import fetch from "auth/FetchInterceptor";

const UploadFileService = {};

UploadFileService.upload = function (payload) {
  return fetch({
    url: "/upload-file",
    method: "post",
    headers: { "Content-Type": "multipart/form-data"},
    data : payload.formData
  });
};

UploadFileService.uploadExtra = function (payload) {

  const headers = { 'Content-Type': 'multipart/form-data' };
debugger
  return fetch({
    url:"/uploadPolyTechEphiExtraTestResult",
    method: "post",
    headers: headers,
    data : payload.payload
  });
};

export default UploadFileService;