import {
  doesThisUserIsIclUser,
} from "utils/CurrentUserUtil";

const dev = {
  API_ENDPOINT_URL: "http://127.0.0.1:8000/api",
  API_BASE_URL: "https://127.0.0.1:8000/api",
  PDF_API_BASE_URL: "http://127.0.0.1:8000/api",
  MICRO_BIOLOGY_PDF_API_BASE_URL: "http://127.0.0.1:8000/api",
};

const prod = {
  // API_ENDPOINT_URL: "https://ephiapi.icladdis.com/api",
  // API_BASE_URL: "https://ephiapi.icladdis.com/api",
  // PDF_API_BASE_URL: "https://ephiapi.icladdis.com/api",
  // MICRO_BIOLOGY_PDF_API_BASE_URL: "https://ephiapi.icladdis.com/api",

  API_ENDPOINT_URL: "http://127.0.0.1:8000/api",
  API_BASE_URL: "https://127.0.0.1:8000/api",
  PDF_API_BASE_URL: "http://127.0.0.1:8000/api",
  MICRO_BIOLOGY_PDF_API_BASE_URL: "http://127.0.0.1:8000/api",

};

const test = {
  API_ENDPOINT_URL: "https://backend.test.icladdis.com/api",
  API_BASE_URL: "https://backend.test.icladdis.com/api",
  PDF_API_BASE_URL: "https://backend.test.icladdis.com/api",
  MICRO_BIOLOGY_PDF_API_BASE_URL: "https://backend.test.icladdis.com/api",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const APP_NAME = "EPHI";
export const IMG_PATH = "/img/EPHI/";
export const ONLY_FOR_CURRENT_COMPANY_LABEL = "Is EPHI USER";
export const ALLOWED_FOR_AGENCIES = false;
export const EXTRA_FEE_FOR_STAT_TESTS = 380;
export const EXTRA_FEE_FOR_QUEUE_PRIORITY = 570;
export const CONFIG_LABEL = doesThisUserIsIclUser() ? "Config" : "Test Menu";
export const PAYMENT_LABEL = doesThisUserIsIclUser() ? "Income" : "Payment";
export const LOGIN_LOGO = "/login-logo.png";
export const env = getEnv();
