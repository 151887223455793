import{
     UPLOAD_FILE_REQUEST,
     UPLOAD_FILE_FAILURE,
     UPLOAD_FILE_SUCCESS,
      UPLOAD_EXTRA_REQUEST,
       UPLOAD_EXTRA_FAILURE,
       UPLOAD_EXTRA_SUCCESS
} from "constants/UploadFile"

export const onUploadFileRequest = (formData, uploadInformation) =>{
     return {
        type : UPLOAD_FILE_REQUEST,
        payload : {
         formData:formData,
         uploadInformation
       }
     }
}

export const onUploadFileSuccess = (payload) =>{
    return {
       type : UPLOAD_FILE_SUCCESS,
       payload : payload
    }
}


export const onUploadFileFailure = (errorMessage) =>{
    return {
       type : UPLOAD_FILE_FAILURE,
       paylaod : errorMessage
    }
}



export const onUploadExtraResultRequest = (paylaod) =>{
   return {
      type : UPLOAD_EXTRA_REQUEST,
      payload : paylaod
   }
}

export const onUploadExtraSuccess = (payload) =>{
   return {
      type : UPLOAD_EXTRA_SUCCESS,
      payload : payload
   }
}


export const onUploadExtraFailure = (errorMessage) =>{
   return {
      type : UPLOAD_EXTRA_FAILURE,
      paylaod : errorMessage
   }
}
