import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import {
    FETCH_SITE_NAMES_REQUEST,
} from "constants/AdminDashboardReports/SiteNames";
import {
   fetchSiteNamesSuccess,
   fetchSiteNamesFailure
} from "redux/actions/AdminDashboardReports/SiteNames";

import SiteNamesService from "services/AdminDashboardReports/SiteNames";

export function* onFetchSiteNames() {
  yield takeLatest(FETCH_SITE_NAMES_REQUEST, function* () {
    try {

      const result = yield call(SiteNamesService.get);
       if (result.status) {
        yield put(fetchSiteNamesSuccess(result.data));
      } else {
        yield put(fetchSiteNamesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchSiteNamesFailure(error.message));
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(onFetchSiteNames),
  ]);
}
