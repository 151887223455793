import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import {
  FETCH_DISCONTINUED_TEST_DIRECTORIES_REQUEST,
} from "constants/DiscontinuedTestDirectories";
import {
  fetchDiscontinuedTestDirectoriesFailure,
  fetchDiscontinuedTestDirectoriesSuccess,
} from "redux/actions";
import DiscontinuedTestDirectoryService from "services/DiscontinuedTestDirectories";

export function* onFetchDiscontinuedTestDirectories() {
  yield takeLatest(FETCH_DISCONTINUED_TEST_DIRECTORIES_REQUEST, function* () {
    try {
      const result = yield call(DiscontinuedTestDirectoryService.index);
      if (result.status) {
        yield put(fetchDiscontinuedTestDirectoriesSuccess(result.data));
      } else {
        yield put(fetchDiscontinuedTestDirectoriesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDiscontinuedTestDirectoriesFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchDiscontinuedTestDirectories),
  ]);
}
