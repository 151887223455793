import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_NOTYETRELEASED_REQUEST } from "constants/Notyetreleased";
import {
  fetchNotyetreleasedFailure,
  fetchNotyetreleasedSuccess,
} from "redux/actions";
import NotyetreleasedService from "services/Notyetreleased";
import { message } from "antd";
export function* onFetchNotyetreleased() {
  yield takeLatest(FETCH_NOTYETRELEASED_REQUEST, function* (payload) {
    try { 
      const result = yield call(NotyetreleasedService.index, payload.payload);
      if (result.status) {
        yield put(fetchNotyetreleasedSuccess(result.data));
      } else {
        yield put(fetchNotyetreleasedFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(fetchNotyetreleasedFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchNotyetreleased)]);
}
