import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_TESTOBX_PER_TECHNICIAN_REQUEST } from "constants/TestobxPerTechnician";
import {
     fetchTestObxPerTechnicianFailure, fetchTestObxPerTechnicianSuccess
} from "../actions/TestObxPerTechnician";

import TestobxPerTechnicianService from "services/TestObxPerTechnician";

export function* onFetchTestObxPerTechnician() {
  yield takeLatest(FETCH_TESTOBX_PER_TECHNICIAN_REQUEST, function* (payload) {
    try {
      const result = yield call(TestobxPerTechnicianService.get, payload.payload);
      if (result.status) {
        yield put(fetchTestObxPerTechnicianSuccess(result.data));
      } else {
        yield put(fetchTestObxPerTechnicianFailure(result.message));
      }
    } catch (error) {
        message.error("something went wrong");
      yield put(fetchTestObxPerTechnicianFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchTestObxPerTechnician)]);
}
