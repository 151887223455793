import {
     FETCH_TESTOBX_PER_TECHNICIAN_FAILURE,
     FETCH_TESTOBX_PER_TECHNICIAN_REQUEST,
     FETCH_TESTOBX_PER_TECHNICIAN_SUCCESS
} from "constants/TestobxPerTechnician";

export const fetchTestObxPerTechnicianRequest = (startDate, endDate, agency, department) =>{
    return {
        type : FETCH_TESTOBX_PER_TECHNICIAN_REQUEST,
        payload : startDate, endDate, agency, department
    }
}

export const fetchTestObxPerTechnicianSuccess = (payload) =>{
     return {
         type : FETCH_TESTOBX_PER_TECHNICIAN_SUCCESS, 
         payload : payload
     }
}

export const fetchTestObxPerTechnicianFailure = (payload) =>{
     return {
         type : FETCH_TESTOBX_PER_TECHNICIAN_FAILURE,
         payload : payload
     }
}