import{
    FETCH_FAULT_RESULT_FAILURE,
    FETCH_FAULT_RESULT_SUCCESS,
    FETCH_FAULT_RESULT_REQUEST
   } from "constants/FaultResults"

   export const fetchFaultResultRequest = (startDate, endDate, agency, department) =>{
       return {
           type : FETCH_FAULT_RESULT_REQUEST,
           payload :
            { 
               startDate,
               endDate, 
               agency,
               department
           }
       }
   }
   
   export const fetchFaultResultSuccess = (payload) =>{
        return {
            type : FETCH_FAULT_RESULT_SUCCESS,
            payload : payload
        }  
   }
   
   export const fetchFaultResultFailure = (payload) =>{
        return { 
           type : FETCH_FAULT_RESULT_FAILURE, 
           payload : payload
        }
   }