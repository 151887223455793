import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_CHECK_LIST_REQUEST,
  DELETE_CHECK_LIST_REQUEST,
  FETCH_CHECK_LISTS_REQUEST,
  UPDATE_CHECK_LIST_REQUEST,
} from "constants/CheckList";
import {
  createCheckListFailure,
  createCheckListSuccess,
  deleteCheckListFailure,
  deleteCheckListSuccess,
  fetchCheckListsFailure,
  fetchCheckListsSuccess,
  setCheckListValidationErrors,
  updateCheckListFailure,
  updateCheckListSuccess,
} from "redux/actions";
import CheckListService from "services/CheckList";

export function* onFetchCheckLists() {
  yield takeLatest(FETCH_CHECK_LISTS_REQUEST, function* () {
    try {
      const result = yield call(CheckListService.index);
      if (result.status) {
        yield put(fetchCheckListsSuccess(result.data));
      } else {
        yield put(fetchCheckListsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchCheckListsFailure(error.message));
    }
  });
}

export function* onUpdateCheckList() {
  yield takeLatest(UPDATE_CHECK_LIST_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        CheckListService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateCheckListSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setCheckListValidationErrors(result.data.errors));
        yield put(updateCheckListFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateCheckListFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateCheckListFailure(error.message));
    }
  });
}

export function* onDeleteCheckList() {
  yield takeLatest(DELETE_CHECK_LIST_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CheckListService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteCheckListSuccess(result.data));
      } else {
        yield put(deleteCheckListFailure(result.message));
      }
    } catch (error) {
      yield put(deleteCheckListFailure(error.message));
    }
  });
}

export function* onCreateCheckList() {
  yield takeLatest(CREATE_CHECK_LIST_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CheckListService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createCheckListSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setCheckListValidationErrors(result.data.errors));
        yield put(createCheckListFailure(result.message));
      } else {
        yield put(createCheckListFailure(result.message));
      }
    } catch (error) {
      yield put(deleteCheckListFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchCheckLists),
    fork(onUpdateCheckList),
    fork(onDeleteCheckList),
    fork(onCreateCheckList),
  ]);
}
