export const SHOW_PATIENTRESULT_NEW_FORM = "SHOW_PATIENTRESULT_NEW_FORM";
export const SHOW_PATIENTRESULT_EDIT_FORM = "SHOW_PATIENTRESULT_EDIT_FORM";
export const SET_PATIENTRESULT_VALIDATION_ERRORS = "SET_PATIENTRESULT_VALIDATION_ERRORS";

export const FETCH_PENDINGRESULTS_REQUEST = "FETCH_PENDINGRESULTS_REQUEST";
export const FETCH_PENDINGRESULTS_SUCCESS = "FETCH_PENDINGRESULTS_SUCCESS";
export const FETCH_PENDINGRESULTS_FAILURE = "FETCH_PENDINGRESULTS_FAILURE";

export const UPDATE_PATIENTRESULT_REQUEST = "UPDATE_PATIENTRESULT_REQUEST";
export const UPDATE_PATIENTRESULT_SUCCESS = "UPDATE_PATIENTRESULT_SUCCESS";
export const UPDATE_PATIENTRESULT_FAILURE = "UPDATE_PATIENTRESULT_FAILURE";

export const DELETE_PATIENTRESULT_REQUEST = "DELETE_PATIENTRESULT_REQUEST";
export const DELETE_PATIENTRESULT_SUCCESS = "DELETE_PATIENTRESULT_SUCCESS";
export const DELETE_PATIENTRESULT_FAILURE = "DELETE_PATIENTRESULT_FAILURE";

export const CREATE_PATIENTRESULT_REQUEST = "CREATE_PATIENTRESULT_REQUEST";
export const CREATE_PATIENTRESULT_SUCCESS = "CREATE_PATIENTRESULT_SUCCESS";
export const CREATE_PATIENTRESULT_FAILURE = "CREATE_PATIENTRESULT_FAILURE";
