
import {
  DashboardOutlined,
  HomeOutlined,
  OrderedListOutlined,
  BranchesOutlined,
  DollarCircleOutlined,
  DeleteOutlined,
  ClusterOutlined,
  ShoppingCartOutlined,
  ExperimentOutlined,
  CheckCircleOutlined,
  IssuesCloseOutlined,
  ClockCircleOutlined,
  FieldTimeOutlined,
  ProfileOutlined,
  MedicineBoxOutlined,
  BarcodeOutlined,
  WarningOutlined,
  BarChartOutlined,
  SafetyCertificateOutlined,
  ReconciliationOutlined,
  QuestionCircleOutlined,
  FlagOutlined,
  UserOutlined,
  TeamOutlined,
  UserAddOutlined,
  SettingOutlined,
  UploadOutlined,
  ExclamationOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import {
  ADMIN_PREFIX_PATH,
  APP_PREFIX_PATH,
  QC_PREFIX_PATH,
  ROLE_GUEST,
  ROLE_QUALITY_CONTROL,
  ROLE_SUPER_ADMIN,
} from "configs/AppConfig";
import { CURRENT_USER } from "redux/constants/Auth";
import {
  VIEW_AGENCIES_TESTS,
  VIEW_ALL_DISCREPANCIES,
  VIEW_ALL_ORDERS,
  VIEW_ALL_RELEASED_RESULTS,
  VIEW_APPROVED_DESCREPANCIES,
  VIEW_BRANCHLOOKUPS,
  VIEW_BRANCH_TEST_CAPACITIES,
  VIEW_CLIENTCOMPANIES,
  VIEW_CLIENTCOMPANYTESTDIRECTORIES,
  VIEW_COUPONS,
  VIEW_DASHBOARD,
  VIEW_DECLINED_DESCREPANCIES,
  VIEW_DEPARTMENTS,
  VIEW_PANEL_OBR,
  VIEW_FINANCIAL,
  VIEW_INPERSON_RESULTS,
  VIEW_NEWORDERS,
  VIEW_NOT_RELEASED_ORDERS,
  VIEW_PANIC_AND_ABNORMAL_TESTS,
  VIEW_PATIENT_RESULT_HISTORY,
  VIEW_PENDING_DISCREPANCIES,
  VIEW_RELEASED_PANEL_FOR_ICL,
  VIEW_REPORTS,
  VIEW_RESULTS_FOR_GUEST_USERS,
  VIEW_ROLES,
  VIEW_SAMPLE_NOT_RECIEVED_ORDERS,
  VIEW_TESTDIRECTORIES,
  VIEW_TOP_AGENCIES,
  VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS,
  VIEW_TOP_TESTS,
  VIEW_USERS,
  VIEW_APPROVED_ORDERS,
  VIEW_PENDING_ORDERS,
  VIEW_DELCINED_ORDERS,
  VIEW_AGENCY_TEST_ASSOCIATION_FORM,
  CREATE_NEWORDERS,
  VIEW_ALL_INCOMPLETE_RESULTS,
  VIEW_PANIC_STAT_RESULTS,
  VIEW_ORDER_STAT_RESULTS,
  VIEW_RELEASED_AFTER_TAT,
  VIEW_PENDING_RESULTS_OBX,
  VIEW_MICRO_BIOLOGY_RESULTS,
  UPLOAD_MICRO_BIOLOGIES_RESULTS,
  VIEW_TOP_PANELS,
  DAILY_RELEASED_TESTS,
  DAILY_DEPARTMENT_RELEASED_TESTS,
  VIEW_COMPLETED_RESULTS,
  VIEW_DISCONTINUED_TEST_DIRECTORIES,
  VIEW_FAULT_RESULTS,
  DAILY_SINGLE_RELEASED_TESTS,
  VIEW_REFERABLE_ORDERS,
  VIEW_TEST_OBX_PER_TECHNICIAN,
  VIEW_STAT_ORDERS,
  VIEW_TAT_APPROACHING,
} from "./UserPermissions";
import { DoesThisUserHasAPermissionToThisComponent, doesThisUserIsIclUser } from "utils/CurrentUserUtil";
import { CONFIG_LABEL } from "./EnvironmentConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "Home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "neworder",
  //   path: `${APP_PREFIX_PATH}/neworder`,
  //   title: "NewOrder",
  //   icon: DashboardOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
];

const qualityControlNavTree = [
  {
    key: "sections",
    path: `${QC_PREFIX_PATH}/sections`,
    title: "Section",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "machines",
    path: `${QC_PREFIX_PATH}/machines`,
    title: "Machine",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "equipment",
    path: `${QC_PREFIX_PATH}/equipment`,
    title: "Equipment",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "check-lists",
    path: `${QC_PREFIX_PATH}/check-lists`,
    title: "CheckList",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "maintenances",
    path: `${QC_PREFIX_PATH}/maintenances`,
    title: "Maintenance",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
const role = currentUser?.role;
let navigationConfig = null;

if (
  (role != ROLE_QUALITY_CONTROL && role != ROLE_GUEST) ||
  role == ROLE_SUPER_ADMIN
) {
  navigationConfig = [
    ...(DoesThisUserHasAPermissionToThisComponent(VIEW_DASHBOARD)
      ? [
       {
          key: "home",
          path: `${ADMIN_PREFIX_PATH}/`,
          title: "Dashboard",
          icon: DashboardOutlined,
          breadcrumb : true,
          submenu: [],
        },
        ]
      : []),
  

    ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ROLES) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_USERS) ||
    // || DoesThisUserHasAPermissionToThisComponent(VIEW_COUPONS)
    DoesThisUserHasAPermissionToThisComponent(VIEW_DEPARTMENTS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCHLOOKUPS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANIES) ||
    DoesThisUserHasAPermissionToThisComponent(
      VIEW_AGENCY_TEST_ASSOCIATION_FORM
    ) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCHLOOKUPS)
      ? [
          {
            key: "configs",
            path: `${APP_PREFIX_PATH}/configs`,
            title: CONFIG_LABEL,
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [
              ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ROLES) ||
              DoesThisUserHasAPermissionToThisComponent(VIEW_USERS)
                ? [
                    {
                      key: "usermanagement",
                      path: `${ADMIN_PREFIX_PATH}/client`,
                      title: doesThisUserIsIclUser()  ? "User Management" : "Change Your Password",
                      icon: TeamOutlined,
                      breadcrumb: true,
                      submenu: [
                        ...(DoesThisUserHasAPermissionToThisComponent(
                          VIEW_ROLES
                        )
                          ? [
                              {
                                key: "role",
                                path: `${ADMIN_PREFIX_PATH}/roles`,
                                title: "Roles",
                                icon: UserAddOutlined,
                                breadcrumb : true,
                                submenu: [],
                              },
                            ]
                          : []),

                        ...(DoesThisUserHasAPermissionToThisComponent(
                          VIEW_USERS
                        )
                          ? [
                              {
                                key: "user",
                                path: `${ADMIN_PREFIX_PATH}/users`,
                                title: "Users",
                                icon: UserOutlined,
                                breadcrumb : true,
                                submenu: [],
                              },
                            ]
                          : []),
                      ],
                    },
                  ]
                : []),

              // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_COUPONS)
              // ? ([{
              //   key: "coupon",
              //   path: `${ADMIN_PREFIX_PATH}/coupons`,
              //   title: "Coupons",
              //   icon: UserOutlined,
              //   submenu: [],
              // }]) : []),

              ...(DoesThisUserHasAPermissionToThisComponent(
                VIEW_CLIENTCOMPANIES
              ) ||
              // || DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANYTESTDIRECTORIES)
              // || DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCH_TEST_CAPACITIES)
              DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCHLOOKUPS) ||
              DoesThisUserHasAPermissionToThisComponent(
                VIEW_AGENCY_TEST_ASSOCIATION_FORM
              ) ||
              DoesThisUserHasAPermissionToThisComponent(VIEW_DEPARTMENTS) ||
              DoesThisUserHasAPermissionToThisComponent(
                VIEW_DISCONTINUED_TEST_DIRECTORIES
              )
                ? [
                    {
                      key: "client",
                      path: `${ADMIN_PREFIX_PATH}/client`,
                      title: CONFIG_LABEL,
                      icon: SettingOutlined,
                      breadcrumb: true,
                      submenu: [
                        ...(DoesThisUserHasAPermissionToThisComponent(
                          VIEW_DEPARTMENTS
                        )
                          ? [
                              {
                                key: "department",
                                path: `${ADMIN_PREFIX_PATH}/departments`,
                                title: "Departments",
                                icon: ClusterOutlined,
                                breadcrumb : true,
                                submenu: [],
                              },
                            ]
                          : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_CLIENTCOMPANIES
                        // )
                        //   ? [
                        //       {
                        //         key: "client-list",
                        //         path: `${ADMIN_PREFIX_PATH}/client-companies`,
                        //         title: "Agency Definition",
                        //         icon: UserOutlined,
                        //         breadcrumb: true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_DISCONTINUED_TEST_DIRECTORIES
                        // )
                        //   ? [
                        //       {
                        //         key: "discontined-test-directories",
                        //         path: `${ADMIN_PREFIX_PATH}/discontinued-test-directories`,
                        //         title:
                        //           "Discontinued Test Definition (Test Directory)",
                        //         icon: OrderedListOutlined,
                        //         breadcrumb: true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANYTESTDIRECTORIES)
                        //   ? ([{
                        //     key: "client-test-directories",
                        //     path: `${ADMIN_PREFIX_PATH}/client-company-test-directories`,
                        //     title: "Client-Test Directories",
                        //     icon: OrderedListOutlined,
                        //     breadcrumb: true,
                        //     submenu: [],
                        //   }]) : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_AGENCY_TEST_ASSOCIATION_FORM
                        // )
                        //   ? [
                        //       {
                        //         key: "agencytests",
                        //         path: `${ADMIN_PREFIX_PATH}/agencies-tests`,
                        //         title: "Assign Test to Agency",
                        //         icon: UserOutlined,
                        //         breadcrumb : true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_BRANCHLOOKUPS
                        // )
                        //   ? [
                        //       {
                        //         key: "branch-look-ups",
                        //         path: `${ADMIN_PREFIX_PATH}/branch-look-ups`,
                        //         title: "Branch Lookup",
                        //         icon: BranchesOutlined,
                        //         breadcrumb: true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCH_TEST_CAPACITIES)
                        //   ? ([{
                        //     key: "branch-test-capacities",
                        //     path: `${ADMIN_PREFIX_PATH}/branch-test-capacities`,
                        //     title: "Branch Test Capacity",
                        //     icon: BranchesOutlined,
                        //     breadcrumb: true,
                        //     submenu: [],
                        //   }]) : []),
                      ],
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

      ...(DoesThisUserHasAPermissionToThisComponent(
        VIEW_TESTDIRECTORIES
      )
        ? [
            {
              key: "test-directories",
              path: `${ADMIN_PREFIX_PATH}/test-directories`,
              title: doesThisUserIsIclUser() ?  "Test Definition (Test Directory)" : "Test Directory",
              icon: OrderedListOutlined,
              breadcrumb: true,
              submenu: [],
            },
          ]
        : []),

    ...(DoesThisUserHasAPermissionToThisComponent(VIEW_NEWORDERS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_ORDERS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_ORDERS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_DELCINED_ORDERS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_NOT_RELEASED_ORDERS) ||
    DoesThisUserHasAPermissionToThisComponent(
      VIEW_SAMPLE_NOT_RECIEVED_ORDERS
    ) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_PANEL_FOR_ICL) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_RELEASED_RESULTS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_RESULTS_FOR_GUEST_USERS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_PANIC_AND_ABNORMAL_TESTS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_INPERSON_RESULTS) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_PATIENT_RESULT_HISTORY) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_DISCREPANCIES) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_DESCREPANCIES) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_DISCREPANCIES) ||
    DoesThisUserHasAPermissionToThisComponent(VIEW_DECLINED_DESCREPANCIES)
      ? [
          {
            key: "activities",
            path: `${APP_PREFIX_PATH}/activities`,
            title: "Activities",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [
              // ...(DoesThisUserHasAPermissionToThisComponent(CREATE_NEWORDERS)
              //   ? [
              //       {
              //         key: "palceorder",
              //         path: `${ADMIN_PREFIX_PATH}/neworder`,
              //         title: "Place Order",
              //         icon: ShoppingCartOutlined,
              //         submenu: [],
              //       },
              //     ]
              //   : []),

              // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) ||
              // DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_ORDERS) ||
              // DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_ORDERS) ||
              // DoesThisUserHasAPermissionToThisComponent(VIEW_DELCINED_ORDERS) ||
              // DoesThisUserHasAPermissionToThisComponent(
              //   VIEW_NOT_RELEASED_ORDERS
              // ) ||
              // DoesThisUserHasAPermissionToThisComponent(
              //   VIEW_SAMPLE_NOT_RECIEVED_ORDERS
              // ) ||
              // DoesThisUserHasAPermissionToThisComponent(VIEW_ORDER_STAT_RESULTS)
              //   ? [
              //       {
              //         key: "orders",
              //         path: `${ADMIN_PREFIX_PATH}/client`,
              //         title: "Order Status",
              //         icon: MedicineBoxOutlined,
              //         breadcrumb: true,
              //         submenu: [
              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_ALL_ORDERS
              //           )
              //             ? [
              //                 {
              //                   key: "released",
              //                   path: `${ADMIN_PREFIX_PATH}/all-orders`,
              //                   title: "All",
              //                   icon: ProfileOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),

              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_PENDING_ORDERS
              //           )
              //             ? [
              //                 {
              //                   key: "pending-results",
              //                   path: `${ADMIN_PREFIX_PATH}/pending-orders`,
              //                   title: " Order to be sent to lab",
              //                   icon: ClockCircleOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),

              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_STAT_ORDERS
              //           )
              //             ? [
              //                 {
              //                   key: "stat-orders",
              //                   path: `${ADMIN_PREFIX_PATH}/stat-orders`,
              //                   title: "Stat Orders",
              //                   icon: ClockCircleOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),

              //           // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_ORDERS)
              //           //   ? ([{
              //           //     key: "approved-results",
              //           //     path: `${ADMIN_PREFIX_PATH}/approved-orders`,
              //           //     title: "Accepted",
              //           //     icon: CheckCircleOutlined,
              //           //     breadcrumb: true,
              //           //     submenu: [],
              //           //   }]) : []),

              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_ORDER_STAT_RESULTS
              //           )
              //             ? [
              //                 {
              //                   key: "statprder",
              //                   path: `${ADMIN_PREFIX_PATH}/order-stat`,
              //                   title: "Stat (Coming Soon...)",
              //                   icon: WarningOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),

              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_SAMPLE_NOT_RECIEVED_ORDERS
              //           )
              //             ? [
              //                 {
              //                   key: "sampleaccepted",
              //                   path: `${ADMIN_PREFIX_PATH}/sample-accepted`,
              //                   title: "Sample Accepted",
              //                   icon: FieldTimeOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),

              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_REFERABLE_ORDERS
              //           )
              //             ? [
              //                 {
              //                   key: "referrableorders",
              //                   path: `${ADMIN_PREFIX_PATH}/referable-orders`,
              //                   title: "Referral Orders",
              //                   icon: FieldTimeOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),

              //           // ...(DoesThisUserHasAPermissionToThisComponent(
              //           //   VIEW_NOT_RELEASED_ORDERS
              //           // )
              //           //   ? [
              //           //       {
              //           //         key: "notyetreleased",
              //           //         path: `${ADMIN_PREFIX_PATH}/not-yet-released`,
              //           //         title: "Accepted But Unreleased Orders",
              //           //         icon: ClockCircleOutlined,
              //           //         breadcrumb: true,
              //           //         submenu: [],
              //           //       },
              //           //     ]
              //           //   : []),

              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_SAMPLE_NOT_RECIEVED_ORDERS
              //           )
              //             ? [
              //                 {
              //                   key: "sampleinprogress",
              //                   path: `${ADMIN_PREFIX_PATH}/sample-in-process`,
              //                   title: "Sample Processing In Lab",
              //                   icon: FieldTimeOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),

              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_DELCINED_ORDERS
              //           )
              //             ? [
              //                 {
              //                   key: "declined-results",
              //                   path: `${ADMIN_PREFIX_PATH}/declined-orders`,
              //                   title: "Rejected",
              //                   icon: DeleteOutlined,
              //                   iconColor: "#550000",
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),
              //         ],
              //       },
              //     ]
              //   : []),

              ...(DoesThisUserHasAPermissionToThisComponent(
                VIEW_ALL_RELEASED_RESULTS
              ) ||
              //  || DoesThisUserHasAPermissionToThisComponent(VIEW_RESULTS_FOR_GUEST_USERS)
              DoesThisUserHasAPermissionToThisComponent(
                VIEW_MICRO_BIOLOGY_RESULTS
              ) ||
              DoesThisUserHasAPermissionToThisComponent(
                VIEW_PANIC_AND_ABNORMAL_TESTS
              ) ||
              DoesThisUserHasAPermissionToThisComponent(
                VIEW_INPERSON_RESULTS
              ) ||
              DoesThisUserHasAPermissionToThisComponent(
                VIEW_PATIENT_RESULT_HISTORY
              ) ||
              DoesThisUserHasAPermissionToThisComponent(
                VIEW_PANIC_STAT_RESULTS
              ) ||
              DoesThisUserHasAPermissionToThisComponent(
                VIEW_ALL_INCOMPLETE_RESULTS
              ) ||
              DoesThisUserHasAPermissionToThisComponent(
                VIEW_COMPLETED_RESULTS
              ) ||
              DoesThisUserHasAPermissionToThisComponent(VIEW_FAULT_RESULTS)
                ? [
                    {
                      key: "results",
                      path: `${ADMIN_PREFIX_PATH}/client`,
                      title: "Results",
                      icon: BarcodeOutlined,
                      breadcrumb: true,
                      submenu: [
                        ...(DoesThisUserHasAPermissionToThisComponent(
                          VIEW_ALL_RELEASED_RESULTS
                        )
                          ? [
                              {
                                key: "patientresult",
                                path: `${ADMIN_PREFIX_PATH}/patient-result`,
                                title: "All",
                                icon: ProfileOutlined,
                                breadcrumb: true,
                                submenu: [],
                              },
                            ]
                          : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_COMPLETED_RESULTS
                        // )
                        //   ? [
                        //       {
                        //         key: "Completed",
                        //         path: `${ADMIN_PREFIX_PATH}/completed-results`,
                        //         title: "Completed",
                        //         icon: CheckCircleOutlined,
                        //         breadcrumb: true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_ALL_INCOMPLETE_RESULTS
                        // )
                        //   ? [
                        //       {
                        //         key: "Incomplete",
                        //         path: `${ADMIN_PREFIX_PATH}/incomplete-results`,
                        //         title: "Incomplete",
                        //         icon: ClockCircleOutlined,
                        //         breadcrumb: true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_MICRO_BIOLOGY_RESULTS
                        // )
                        //   ? [
                        //       {
                        //         key: "micro-biology-results",
                        //         path: `${ADMIN_PREFIX_PATH}/micro-biology`,
                        //         title: "Micro and Pathology Results",
                        //         icon: QuestionCircleOutlined,
                        //         breadcrumb: true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(VIEW_RESULTS_FOR_GUEST_USERS)
                        // ? ([{
                        //   key: "ordermadebyguestuser",
                        //   path: `${ADMIN_PREFIX_PATH}/ordermadebyguestusers`,
                        //   title: "For Guest Users",
                        //   icon: UserOutlined,
                        //   breadcrumb: true,
                        //   submenu: [],
                        // }]) : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_PANIC_STAT_RESULTS
                        // )
                        //   ? [
                        //       {
                        //         key: "stat",
                        //         path: `${ADMIN_PREFIX_PATH}/panicandabnormaltests`,
                        //         title: "Stat (Coming Soon...)",
                        //         icon: WarningOutlined,
                        //         breadcrumb : true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_PANIC_AND_ABNORMAL_TESTS
                        // )
                        //   ? [
                        //       {
                        //         key: "panicandabnormaltests",
                        //         path: `${ADMIN_PREFIX_PATH}/panicandabnormaltests`,
                        //         title: "Panic Results",
                        //         icon: FlagOutlined,
                        //         breadcrumb : true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_INPERSON_RESULTS
                        // )
                        //   ? [
                        //       {
                        //         key: "inpersonresultdelivery",
                        //         path: `${ADMIN_PREFIX_PATH}/in-person-result-delivery`,
                        //         title: "Confidential Results",
                        //         icon: SafetyCertificateOutlined,
                        //         breadcrumb : true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_PATIENT_RESULT_HISTORY
                        // )
                        //   ? [
                        //       {
                        //         key: "patienttestresulthistory",
                        //         path: `${ADMIN_PREFIX_PATH}/patienttestresulthistory`,
                        //         title: "Commulative Sum Report",
                        //         icon: BarChartOutlined,
                        //         breadcrumb : true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),

                        // ...(DoesThisUserHasAPermissionToThisComponent(
                        //   VIEW_FAULT_RESULTS
                        // )
                        //   ? [
                        //       {
                        //         key: "fault-results",
                        //         path: `${ADMIN_PREFIX_PATH}/fault-results`,
                        //         title: "Faulty PDF",
                        //         icon: ExclamationOutlined,
                        //         iconColor: "#FF0000",
                        //         breadcrumb: true,
                        //         submenu: [],
                        //       },
                        //     ]
                        //   : []),
                      ],
                    },
                  ]
                : [])
                ,

              // ...(DoesThisUserHasAPermissionToThisComponent(
              //   UPLOAD_MICRO_BIOLOGIES_RESULTS
              // )
              //   // ? [
              //   //     {
              //   //       key: "fileupload",
              //   //       path: `${ADMIN_PREFIX_PATH}/uploadresult`,
              //   //       title: "Upload Result",
              //   //       icon: UploadOutlined,
              //   //       breadcrumb : true,
              //   //       submenu: [],
              //   //     },
              //   //   ]
              //   // : []),
              //   // ...(DoesThisUserHasAPermissionToThisComponent(
              //   //   UPLOAD_MICRO_BIOLOGIES_RESULTS
              //   // )
              //     ? [
              //         {
              //           key: "extraresults",
              //           path: `${ADMIN_PREFIX_PATH}/uploadextratestresult`,
              //           title: "Upload Extra Test Results",
              //           icon: UploadOutlined,
              //           breadcrumb : true,
              //           submenu: [],
              //         },
              //       ]
              //     : []
              //     ),
              // ...(DoesThisUserHasAPermissionToThisComponent(
              //   VIEW_ALL_DISCREPANCIES
              // ) ||
              // DoesThisUserHasAPermissionToThisComponent(
              //   VIEW_APPROVED_DESCREPANCIES
              // ) ||
              // DoesThisUserHasAPermissionToThisComponent(
              //   VIEW_PENDING_DISCREPANCIES
              // ) ||
              // DoesThisUserHasAPermissionToThisComponent(
              //   VIEW_DECLINED_DESCREPANCIES
              // )
              //   ? [
              //       {
              //         key: "discrepancy",
              //         path: `${ADMIN_PREFIX_PATH}/discrepancy`,
              //         title: "Corrected Results",
              //         icon: ReconciliationOutlined,
              //         breadcrumb: true,
              //         submenu: [
              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_ALL_DISCREPANCIES
              //           )
              //             ? [
              //                 {
              //                   key: "discrepancyall",
              //                   path: `${ADMIN_PREFIX_PATH}/discrepancy/all`,
              //                   title: "All",
              //                   icon: ProfileOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),
              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_PENDING_DISCREPANCIES
              //           )
              //             ? [
              //                 {
              //                   key: "discrepancy-pending",
              //                   path: `${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-pending`,
              //                   title: "Pending",
              //                   icon: QuestionCircleOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),
              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_APPROVED_DESCREPANCIES
              //           )
              //             ? [
              //                 {
              //                   key: "discrepancy-approved",
              //                   path: `${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-approved`,
              //                   title: "Approved",
              //                   icon: CheckCircleOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),

              //           ...(DoesThisUserHasAPermissionToThisComponent(
              //             VIEW_DECLINED_DESCREPANCIES
              //           )
              //             ? [
              //                 {
              //                   key: "discrepancy-declined",
              //                   path: `${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-declined`,
              //                   title: "Declined",
              //                   icon: IssuesCloseOutlined,
              //                   breadcrumb: true,
              //                   submenu: [],
              //                 },
              //               ]
              //             : []),
              //         ],
              //       },
              //     ]
              //   : []),
              // ...(DoesThisUserHasAPermissionToThisComponent(
              //   DAILY_RELEASED_TESTS
              // )
              //   ? [
              //       {
              //         key: "daily-released-tests",
              //         path: `${ADMIN_PREFIX_PATH}/daily-released-tests`,
              //         title: "Daily Released Tests",
              //         icon: ExperimentOutlined,
              //         breadcrumb : true,
              //         submenu: [],
              //       },
              //     ]
              //   : []),

              // ...(DoesThisUserHasAPermissionToThisComponent(
              //   DAILY_SINGLE_RELEASED_TESTS
              // )
              //   ? [
              //       {
              //         key: "single-daily-released-tests",
              //         path: `${ADMIN_PREFIX_PATH}/single-daily-released-tests`,
              //         title: "Single Daily Released Tests",
              //         icon: ExperimentOutlined,
              //         breadcrumb : true,
              //         submenu: [],
              //       },
              //     ]
              //   : []),

              // ...(DoesThisUserHasAPermissionToThisComponent(
              //   DAILY_DEPARTMENT_RELEASED_TESTS
              // )
              //   ? [
              //       {
              //         key: "daily-department-released-tests",
              //         path: `${ADMIN_PREFIX_PATH}/daily-department-released-tests`,
              //         title: "Daily Department Released Tests",
              //         icon: ExperimentOutlined,
              //         breadcrumb : true,
              //         submenu: [],
              //       },
              //     ]
              //   : []),

              // ...(DoesThisUserHasAPermissionToThisComponent(
              //   VIEW_TEST_OBX_PER_TECHNICIAN
              // )
              //   ? [
              //       {
              //         key: "testobxpertechnician",
              //         path: `${ADMIN_PREFIX_PATH}/testobx-per-technician`,
              //         title: "TestObx Per Technician",
              //         icon: FieldTimeOutlined,
              //         breadcrumb: true,
              //         submenu: [],
              //       },
              //     ]
              //   : []),
            ],
          },
        ]
      : []),

    // ...(DoesThisUserHasAPermissionToThisComponent(
    //   VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS
    // ) ||
    // DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_AGENCIES) ||
    // DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_TESTS) ||
    // DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_PANEL_FOR_ICL) ||
    // DoesThisUserHasAPermissionToThisComponent(VIEW_PANEL_OBR) ||
    // DoesThisUserHasAPermissionToThisComponent(VIEW_DASHBOARD) ||
    // DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_AFTER_TAT) ||
    // DoesThisUserHasAPermissionToThisComponent(VIEW_REPORTS) ||
    // DoesThisUserHasAPermissionToThisComponent(VIEW_FINANCIAL) ||
    // DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_RESULTS_OBX) ||
    // DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_PANELS)
    //   ? [
    //       {
    //         key: "reports",
    //         path: `${APP_PREFIX_PATH}/reports`,
    //         title: "Reports",
    //         icon: DashboardOutlined,
    //         breadcrumb: false,
    //         submenu: [
    //           ...(DoesThisUserHasAPermissionToThisComponent(
    //             VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS
    //           )
    //             ? [
    //                 {
    //                   key: "topbranchesforwalkincustomer",
    //                   path: `${ADMIN_PREFIX_PATH}/top-branchs-for-walkin-customers`,
    //                   title: "Top Branches for Walk-in-Customers",
    //                   icon: BranchesOutlined,
    //                   submenu: [],
    //                 },
    //               ]
    //             : []),

    //           ...(DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_AFTER_TAT) ? [
    //                 {
    //                   key: "relasedaftertat",
    //                   path: `${ADMIN_PREFIX_PATH}/released-after-tat`,
    //                   title: "Released after TAT",
    //                   icon: ClockCircleOutlined,
    //                   breadcrumb : true,
    //                   submenu: [],
    //                 },
    //               ]
    //             : []),
    //           ...(DoesThisUserHasAPermissionToThisComponent(VIEW_REPORTS)
    //             ? [
    //                 {
    //                   key: "reports",
    //                   path: `${ADMIN_PREFIX_PATH}/reports`,
    //                   title: "TAT-Routine Report",
    //                   icon: ClockCircleOutlined,
    //                   breadcrumb : true,
    //                   submenu: [],
    //                 },
    //               ]
    //             : []),

    //           ...(DoesThisUserHasAPermissionToThisComponent(
    //             VIEW_RELEASED_PANEL_FOR_ICL
    //           )
    //             ? [
    //                 {
    //                   key: "released for icl",
    //                   path: `${ADMIN_PREFIX_PATH}/released-panels`,
    //                   title: "Released Panels For ICL",
    //                   icon: ClusterOutlined,
    //                   breadcrumb : true,
    //                   submenu: [],
    //                 },
    //               ]
    //             : []),

    //           ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_AGENCIES)
    //             ? [
    //                 {
    //                   key: "topagencies",
    //                   path: `${ADMIN_PREFIX_PATH}/top-agencies`,
    //                   title: "Top Agencies",
    //                   icon: ClusterOutlined,
    //                   breadcrumb : true,
    //                   submenu: [],
    //                 },
    //               ]
    //             : []),

    //           ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_TESTS)
    //             ? [
    //                 {
    //                   key: "toptests",
    //                   path: `${ADMIN_PREFIX_PATH}/top-tests`,
    //                   title: "Top Tests",
    //                   icon: ExperimentOutlined,
    //                   breadcrumb : true,
    //                   submenu: [],
    //                 },
    //               ]
    //             : []),

    //           ...(DoesThisUserHasAPermissionToThisComponent(
    //             VIEW_PENDING_RESULTS_OBX
    //           )
    //             ? [
    //                 {
    //                   key: "pendingresults",
    //                   path: `${ADMIN_PREFIX_PATH}/pending-results-obx`,
    //                   title: "Pending Results",
    //                   icon: ClockCircleOutlined,
    //                   breadcrumb : true,
    //                   submenu: [],
    //                 },
    //               ]
    //             : []),

    //           ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_PANELS)
    //             ? [
    //                 {
    //                   key: "toppanels",
    //                   path: `${ADMIN_PREFIX_PATH}/top-panels`,
    //                   title: "Top Orders",
    //                   icon: ClusterOutlined,
    //                   breadcrumb : true,
    //                   submenu: [],
    //                 },
    //               ]
    //             : []),

    //           ...(DoesThisUserHasAPermissionToThisComponent(VIEW_FINANCIAL)
    //             ? [
    //                 {
    //                   key: "financial",
    //                   path: `${ADMIN_PREFIX_PATH}/financial`,
    //                   title: "Financial",
    //                   icon: DollarCircleOutlined,
    //                   submenu: [],
    //                 },
    //               ]
    //             : []),

    //             ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TAT_APPROACHING)
    //             ? [
    //                 {
    //                   key: "tat-approaaching",
    //                   path: `${ADMIN_PREFIX_PATH}/tat-approaching`,
    //                   title: "Tat Approaching",
    //                   icon: ClockCircleOutlined,
    //                   submenu: [],
    //                 },
    //               ]
    //             : []),

    //           // {
    //           //   key: "topreferals",
    //           //   path: `${ADMIN_PREFIX_PATH}/top-referalss`,
    //           //   title: "Referrals (Coming Soon...)",
    //           //   icon: ClusterOutlined,
    //           //   submenu: [],
    //           // },
    //           // {
    //           //   key: "agency-tests-pending",
    //           //   path: `${
    //           //     ADMIN_PREFIX_PATH
    //           //   }/agency-tests-pending`,
    //           //   title: "Agency-Pending Tests (Coming Soon...)",
    //           //   icon: ClusterOutlined,
    //           //   submenu: [
    //           //   ],
    //           // },
    //           // {
    //           //   key: "agency-tests-rejected",
    //           //   path: `${
    //           //     ADMIN_PREFIX_PATH
    //           //   }/agency-tests-rejected`,
    //           //   title: "Agency-Rejected (Coming Soon...)",
    //           //   icon: RestOutlined,
    //           //   submenu: [
    //           //   ],
    //           // },
    //           // {
    //           //   key: "agency-approved-discrepancy",
    //           //   path: `${ADMIN_PREFIX_PATH}/agency-approved-discrepancy`,
    //           //   title: "Agency-Discrepancy (Coming Soon...)",
    //           //   icon: ClusterOutlined,
    //           //   submenu: [],
    //           // },
    //         ],
    //       },
    //     ]
    //   : []),

      // {
      //   key: "Customer Support",
      //   path: `${ADMIN_PREFIX_PATH}/client`,
      //   title: "Customer Support",
      //   icon: TeamOutlined,
      //   breadcrumb: true,
      //   submenu: [
      //     ...(DoesThisUserHasAPermissionToThisComponent(
      //       VIEW_ROLES
      //     )
      //       ? [
      //           {
      //             key: "customer-support",
      //             title: "Contact Us",
      //             path : `${ADMIN_PREFIX_PATH}/contact-us`,
      //             icon: UserAddOutlined,
      //             breadcrumb : true,
      //             submenu: [],
      //           },
      //         ]
      //       : [])]
      // }
  ];
} else if (role === "qc-admin") {
  navigationConfig = [
    ...qualityControlNavTree
  ];
} else navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
