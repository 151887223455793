import { 
    FETCH_SITE_NAMES_REQUEST,
    FETCH_SITE_NAMES_SUCCESS,
    FETCH_SITE_NAMES_FAILURE,  
} from "constants/AdminDashboardReports/SiteNames"

export const fetchSiteNamesRequest = () =>{
     return {
         type : FETCH_SITE_NAMES_REQUEST}
}

export const fetchSiteNamesSuccess = (payload) =>{
    return {
         type : FETCH_SITE_NAMES_SUCCESS,
         payload : payload
    }
}

export const fetchSiteNamesFailure = ( errorMessage ) =>{
    return { 
        type : FETCH_SITE_NAMES_FAILURE,
        payload : errorMessage
    }
    
}