import fetch from "auth/FetchInterceptor";

const DiscrepancyApproved = {};

DiscrepancyApproved.get = function (payload) {
  return fetch({
    url:
      "/reports/discrepancy-approved?" +
      "startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" +
      payload.department,
    method: "get",
  });
};

DiscrepancyApproved.update = function (id, data) {
  return fetch({
    url: `/poly-tech-patient-pid/${id}`,
    method: "put",
    data,
  });
};

DiscrepancyApproved.Discrepancyobx = function (payload) {
  return fetch({
    url: "/reports/discrepancy_obx?" + "sampleId=" + payload.batchUuid + "&batchUuid=" + payload.sampleId,
    method: "get",
  });
};
export default DiscrepancyApproved;
