import fetch from "auth/FetchInterceptor";

const BranchTestCapacityService = {};

BranchTestCapacityService.index = function () {
  return fetch({
    url: "/branch-test-capacities",
    method: "get",
  });
};

BranchTestCapacityService.create = function (data) {
  return fetch({
    url: "/branch-test-capacities",
    method: "post",
    data,
  });
};

BranchTestCapacityService.update = function (id, data) {
  return fetch({
    url: `/branch-test-capacities/${id}`,
    method: "put",
    data,
  });
};

BranchTestCapacityService.delete = function (id) {
  return fetch({
    url: `/branch-test-capacities/${id}`,
    method: "delete",
  });
};

BranchTestCapacityService.available = function (data) {
  return fetch({
    url: `/branch-test-capacities/available`,
    method: "post",
    data,
  });
};
export default BranchTestCapacityService;
