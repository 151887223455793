import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import {
    FETCH_TATAPPROACHING_REQUEST, 
} from "constants/TATApproaching";

import {
  fetchTatApproachingFailure,
  fetchTatApproachingSuccess
} from "redux/actions/TATApproaching";

import TatApproaching from "services/TatApproaching";

export function* onFetchTatApproaching() {
  yield takeLatest(FETCH_TATAPPROACHING_REQUEST, function* (payload) {
    try {
      const result = yield call(TatApproaching.get, payload.payload);
      if (result.status) {
        yield put(fetchTatApproachingSuccess(result.data));
      } else {
        yield put(fetchTatApproachingFailure(result.message));
      }
    } catch (error) {
      yield put(fetchTatApproachingFailure(error.message));
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(onFetchTatApproaching),
  ]);
}
