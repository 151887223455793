import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import {
    FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST,
} from "constants/AdminDashboardReports/DashboardReport";
import {
    fetchAdminReportsSuccess,
    fetchAdminReportsFailure,
} from "redux/actions/AdminDashboardReports/DashboardReport";

import DashboardReport from "services/AdminDashboardReports/DashboardReport";

export function* onFetchAdminDashboardReports() {
  yield takeLatest(FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST, function* (payload) {
    try {

      // const maxWaitTime = 2 * 60 * 1000; // 2 minutes in milliseconds
      // const { result, timeout } = yield race({
      //   result: call(DashboardReport.get, payload.payload),
      //   timeout: delay(maxWaitTime)
      // });

      const result = yield call(DashboardReport.get, payload.payload);
       if (result.status) {
        yield put(fetchAdminReportsSuccess(result.data));
      } else {
        yield put(fetchAdminReportsFailure(result.message));
      }
    } catch (error) {
      // message.error("something went wrong");
      yield put(fetchAdminReportsFailure(error.message));
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(onFetchAdminDashboardReports),
  ]);
}
