import {
  FETCH_STAT_ORDERS_FAILURE,
  FETCH_STAT_ORDERS_REQUEST,
  FETCH_STAT_ORDERS_SUCCESS,
  FETCH_STAT_ORDER_OBX_REQUEST,
  FETCH_STAT_ORDER_OBX_SUCCESS,
  FETCH_STAT_ORDER_OBX_FAILURE,
  UPDATE_STAT_ORDER_REQUEST,
  UPDATE_STAT_ORDER_SUCCESS,
  UPDATE_STAT_ORDER_FAILURE,
  SHOW_STAT_ORDER_OBX_DIALOG,
} from "constants/StatOrders";
import { changeFormatedDatetoHumanReadableFormat } from "utils/DateTimeUtil";
import { updateOrder } from "utils/Order";

const initialState = {
  loading: false,
  loadingObx: false,
  orderStatus: false,
  list: [],
  obxList: [],
  updatedOrder: [],
  errorMessage: "",
  showDialog: false,
};

const StatOrders = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAT_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_STAT_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: changeFormatedDatetoHumanReadableFormat(action.payload),
      };

    case FETCH_STAT_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case FETCH_STAT_ORDER_OBX_REQUEST:
      return {
        ...state,
        loadingObx: true,
      };

    case FETCH_STAT_ORDER_OBX_SUCCESS:
      return {
        ...state,
        loadingObx: false,
        obxList: action.payload,
      };

    case FETCH_STAT_ORDER_OBX_FAILURE:
      return {
        ...state,
        loadingObx: false,
        errorMessage: action.payload,
      };

    case UPDATE_STAT_ORDER_REQUEST:
      return {
        ...state,
        loadingObx: true,
        orderStatus: true,
      };
    case UPDATE_STAT_ORDER_SUCCESS:
      return {
        ...state,
        loadingObx: false,
        orderStatus: false,
        showDialog: false,
        list: updateOrder(state, action),
      };

    case UPDATE_STAT_ORDER_FAILURE:
      return {
        ...state,
        showDialog: false,
        errorMessage: action.payload,
        orderStatus: false,
      };

    case SHOW_STAT_ORDER_OBX_DIALOG:
      return {
        ...state,
        showDialog: action.payload,
      };

    default:
      return {
        state,
      };
  }
};

export default StatOrders;
