import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_MICRO_BIOLOGY_RESULTS_REQUEST } from "constants/MicroBiology";
import {
    fetchMicroBiologyResultsFailure,
    fetchMicroBiologyResultsSuccess
} from "redux/actions";
import MicroBiologyService from "services/MicroBiology";

export function* onFetchMicroBiologyResults() {
  yield takeLatest(FETCH_MICRO_BIOLOGY_RESULTS_REQUEST, function* (payload) {
    try {
      const result = yield call(MicroBiologyService.get, payload.payload);
      if (result.status) {
        yield put(fetchMicroBiologyResultsSuccess(result.data));
      } else {
        message.error("Something went wrong");
        yield put(fetchMicroBiologyResultsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(fetchMicroBiologyResultsFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchMicroBiologyResults)]);
}
