import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_DISCREPANCY_REQUEST,
  FETCH_DISCREPANCYOBX_REQUEST,
  UPDATE_DISCREPANCY_REQUEST,
} from "constants/DiscrepanciesStatus/DiscrepancyDeclined";
import {
  fetchDiscrepancyFailure,
  fetchDiscrepancySuccess,
  fetchDiscrepancyObxFailure,
  updateDiscrepancyFailure,
  updateDiscrepancySuccess,
  fetchDiscrepancyObxSuccess,
} from "redux/actions/DiscrepanciesStatus/DiscrepancyDeclined";

import DiscrepancyDeclined from "services/DiscrepanciesStatus/DiscrepancyDeclined";

export function* onFetchDiscrepancy() {
  yield takeLatest(FETCH_DISCREPANCY_REQUEST, function* (payload) {
    try {
      const result = yield call(DiscrepancyDeclined.get, payload.payload);
      if (result.status) {
        yield put(fetchDiscrepancySuccess(result.data));
      } else {
        yield put(fetchDiscrepancyFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDiscrepancyFailure(error.message));
    }
  });
}

export function* onUpdateDiscrepancy() {
  yield takeLatest(UPDATE_DISCREPANCY_REQUEST, function* ({ payload }) {
    let discrepancyData = [payload];
    try {
      const result = yield call(
        DiscrepancyDeclined.update,
        payload.id,
        discrepancyData
      );
      if (result.status) {
        message.success("Discrepancy Status Changed");
        yield put(updateDiscrepancySuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Error updating discrepancy");
        // yield put(setDiscrepancyValidationErrors(result.data.errors));
        yield put(updateDiscrepancyFailure(result.message));
      } else {
        yield put(updateDiscrepancyFailure(result.message));
      }
    } catch (error) {
      yield put(updateDiscrepancyFailure(error.message));
    }
  });
}

export function* onFetchDiscrepancyObx() {
  yield takeLatest(FETCH_DISCREPANCYOBX_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(DiscrepancyDeclined.Discrepancyobx, payload);
      if (result.status) {
        yield put(fetchDiscrepancyObxSuccess(result.data));
      } else {
        yield put(fetchDiscrepancyObxFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDiscrepancyObxFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchDiscrepancy),
    fork(onFetchDiscrepancyObx),
    fork(onUpdateDiscrepancy),
  ]);
}
