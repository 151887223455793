import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_SINGLE_DAILY_RELEASED_TESTS_REQUEST } from "constants/SingleReleasedDailyReleasedTests";
import {
    fetchSingleDailyReleasedTestsSuccess,
    fetchSingleDailyReleasedTestsFailure
} from "redux/actions/SingleReleasedDailyReleasedTests";
import SingleDailyReleasedTests from "services/SingleReleasedDailyReleasedTests";

export function* onFetchSingleDailyReleasedTests() {
  yield takeLatest(FETCH_SINGLE_DAILY_RELEASED_TESTS_REQUEST, function* (payload) {
    try {
      const result = yield call(SingleDailyReleasedTests.get, payload.payload);
      if (result.status) {
        yield put(fetchSingleDailyReleasedTestsSuccess(result.data));
      } else {
        message.error("Something went wrong");
        yield put(fetchSingleDailyReleasedTestsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(fetchSingleDailyReleasedTestsFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchSingleDailyReleasedTests)]);
}
