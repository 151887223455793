import fetch from "auth/FetchInterceptor";

const CheckListService = {};

CheckListService.index = function () {
  return fetch({
    url: "/check-lists",
    method: "get",
  });
};

CheckListService.create = function (data) {
  return fetch({
    url: "/check-lists",
    method: "post",
    data,
  });
};

CheckListService.update = function (id, data) {
  return fetch({
    url: `/check-lists/${id}`,
    method: "put",
    data,
  });
};

CheckListService.delete = function (id) {
  return fetch({
    url: `/check-lists/${id}`,
    method: "delete",
  });
};

export default CheckListService;