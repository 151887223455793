import{
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_EXTRA_REQUEST,
    UPLOAD_EXTRA_SUCCESS,
    UPLOAD_EXTRA_FAILURE
} from "constants/UploadFile"


const initialState = {
     message : "",
     loading : false,
     errorMessage : ""
}

 const UploadFile = (state =initialState, action) =>{
     switch(action.type){
         case UPLOAD_FILE_REQUEST :
            return{
                 loading: true
            }
         case UPLOAD_FILE_SUCCESS : 
            return{
                 loading : false,
                 message : action.paylaod
            }
         case UPLOAD_FILE_FAILURE : 
            return{
                 loading : false,
                 errorMessage : action.paylaod
            }
            //
            case UPLOAD_EXTRA_REQUEST :
               return{
                    loading: true
               }
            case UPLOAD_EXTRA_SUCCESS : 
               return{
                    loading : false,
                    message : action.paylaod
               }
            case UPLOAD_EXTRA_FAILURE : 
               return{
                    loading : false,
                    errorMessage : action.paylaod
               }
        default : 
         return state;
     }
}
export default UploadFile;