import fetch from "auth/FetchInterceptor";

const PendingResultObxService = {};

PendingResultObxService.get = function (payload) {
  return fetch({
    url:
      "/reports/pendingresultsobx?" +
      "startdate=" +
      payload.startDate +
      "&" +
      "enddate=" +
      payload.endDate +
      "&" +
      "agency=" + payload.agency + "&department=null",
    method: "get",
  });
};

export default PendingResultObxService;
