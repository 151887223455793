import{
    FETCH_PENDINGRESULTS_OBX_REQUEST,
    FETCH_PENDINGRESULTS_OBX_SUCCESS,
    FETCH_PENDINGRESULTS_OBX_FAILURE
   } from "constants/PendingResultObx"
   
   export const fetchPendingResultObxRequest = (startDate, endDate, agency, department) =>{
       return {
           type : FETCH_PENDINGRESULTS_OBX_REQUEST,
           payload :
            { 
               startDate,
               endDate, 
               agency,
               department
            }
       }
   }
   
   export const fetchPendingResultObxSuccess = (payload) =>{
        return {
            type : FETCH_PENDINGRESULTS_OBX_SUCCESS,
            payload : payload
        }  
   }
   
   export const fetchPendingResultObxFailure = (payload) =>{
        return { 
           type : FETCH_PENDINGRESULTS_OBX_FAILURE, 
           payload : payload
        }
   }