import { 
    FETCH_SITE_NAMES_REQUEST,
    FETCH_SITE_NAMES_SUCCESS,
    FETCH_SITE_NAMES_FAILURE,
} from "constants/AdminDashboardReports/SiteNames"

const initState = {
     SiteNames : [],
     loading : true,
     errorMessage : ""
}

const SiteNames = (state = initState, action) =>{
    switch(action.type)
    {
        case FETCH_SITE_NAMES_REQUEST :
        return { 
            ...state,
            loading : true
        }
        case FETCH_SITE_NAMES_SUCCESS : 
        return {
            ...state,
            loading : false,
            SiteNames : action.payload
        }
        case FETCH_SITE_NAMES_FAILURE : 
        return { 
             ...state, 
             loading : false,
             SiteNames : [],
             errorMessage : action.payload
        }
        default : 
        return state; // Change this line
    }
}

export default SiteNames;
