import fetch from "auth/FetchInterceptor";

const MicroBiologyService = {};

MicroBiologyService.get = function (payload) {
  return fetch({
    url: "/reports/micro-biology-results?" + 
    'startdate=' + payload.startDate + 
    '&enddate=' + payload.endDate +
    '&agency=' + payload.agency +
    '&department=' + payload.department,
    method: "get",
  });
};

export default MicroBiologyService;