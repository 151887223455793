import { 
    ICL_PAY_FAILURE,
    ICL_PAY_REQUEST,
    ICL_PAY_SUCCESS,
    UN_CLICK_SUMMIT_BUTTON
} from "constants/IclPay";

const initialState = {
    proccessing : false,
    errorMessage : "",
    paymentInformationResponse : {},
    clickTheSubmitButton : false
}

const IclPay = (state = initialState, action) =>{
    switch(action.type){
         case  ICL_PAY_REQUEST :
         return {
            proccessing : true,
         }
         
         case ICL_PAY_SUCCESS : 
         return {
            proccessing : false,
             paymentInformationResponse : action.payload,
             clickTheSubmitButton : true
         }

         case ICL_PAY_FAILURE : 
         return { 
            proccessing : false,
         }
         case UN_CLICK_SUMMIT_BUTTON: 
         return {
            clickTheSubmitButton : false
         }
         default : 
         return state;
        }
}

export default IclPay;