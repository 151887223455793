import moment from "moment";
import {
  DATE_AND_TIME_FOR_TABLE_FORMAT, MONTH_DAY_YEAR_WHILE_MONTH_IN_STRING_FORMAT,
} from "../constants/DateTimeUtilConstants";
export const changeDateFormatAndReturnNewDate = (oldDate, newFormat) => {
  const newDate = moment(oldDate, "YYYYMMDDhhmmss").format(newFormat);
  //const newDate = moment(oldDate).format(newFormat);
  return newDate;
};

export const initializeTheMomentStartOfWeek =() =>{
  moment.updateLocale('en', {
    week: {
      dow : 1, // Monday is the first day of the week.
    }
  });
}

export const changeDateToHumanReadableFormat = (list) => {
  const listContainingHumanReadableDate = list.map((singleJson) => {
    const timeRecieved = singleJson.obrDateTimeSpecimenReceived;
    const timeReleased = singleJson.obrDateTimeResultReleasedLatest;
    const timeCollected = singleJson.obrDateTimeSpecimenCollected;

    // Check if the timeRecieved, timeReleased, and timeCollected are not undefined and not empty strings
    if (timeRecieved && timeReleased && timeCollected) {
      const formatedTimeRecieved = timeRecieved.slice(0, 8) + "T" + timeRecieved.slice(8);
      const formatedTimeReleased = timeReleased.slice(0, 8) + "T" + timeReleased.slice(8);
      const formatedTimeCollected = timeCollected.slice(0, 8) + "T" + timeCollected.slice(8);

      singleJson.obrDateTimeSpecimenReceived = changeDateFormatAndReturnNewDate(
        formatedTimeRecieved,
        DATE_AND_TIME_FOR_TABLE_FORMAT
      );
      singleJson.obrDateTimeResultReleasedLatest = changeDateFormatAndReturnNewDate(
        formatedTimeReleased,
        DATE_AND_TIME_FOR_TABLE_FORMAT
      );
      singleJson.obrDateTimeSpecimenCollected = changeDateFormatAndReturnNewDate(
        formatedTimeCollected,
        DATE_AND_TIME_FOR_TABLE_FORMAT
      );
    } else {
      // Handle the case where one or more date and time values are missing
      // You can set default values or handle the error in any other way that makes sense for your application.
    }

    return singleJson;
  });

  return listContainingHumanReadableDate;
};


export const changeFormatedDatetoHumanReadableFormat = (list) =>{
  const listContainingHumanReadableDate = list.map(singleJson =>{
    singleJson.webAgeOrDateOfBirth = changeDateFormatAndReturnNewDate( singleJson.webAgeOrDateOfBirth, MONTH_DAY_YEAR_WHILE_MONTH_IN_STRING_FORMAT);
    return singleJson;
  });
  return listContainingHumanReadableDate;
}