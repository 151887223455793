export const FETCH_STAT_ORDERS_REQUEST = "FETCH_STAT_ORDERS_REQUEST";
export const FETCH_STAT_ORDERS_SUCCESS = "FETCH_STAT_ORDERS_SUCCESS";
export const FETCH_STAT_ORDERS_FAILURE = "FETCH_STAT_ORDERS_FAILURE";

export const FETCH_STAT_ORDER_OBX_REQUEST = "FETCH_STAT_ORDER_OBX_REQUEST";
export const FETCH_STAT_ORDER_OBX_SUCCESS = "FETCH_STAT_ORDER_OBX_SUCCESS";
export const FETCH_STAT_ORDER_OBX_FAILURE = "FETCH_STAT_ORDER_OBX_FAILURE";

export const UPDATE_STAT_ORDER_REQUEST = "UPDATE_STAT_ORDER_REQUEST";
export const UPDATE_STAT_ORDER_SUCCESS = "UPDATE_STAT_ORDER_SUCCESS";
export const UPDATE_STAT_ORDER_FAILURE = "UPDATE_STAT_ORDER_FAILURE";

export const SHOW_STAT_ORDER_OBX_DIALOG = "SHOW_STAT_ORDER_OBX_DIALOG";
