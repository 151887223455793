export const SHOW_PATIENTTESTRESULTHISTORY_NEW_FORM = "SHOW_PATIENTTESTRESULTHISTORY_NEW_FORM";
export const SHOW_PATIENTTESTRESULTHISTORY_EDIT_FORM = "SHOW_PATIENTTESTRESULTHISTORY_EDIT_FORM";
export const SET_PATIENTTESTRESULTHISTORY_VALIDATION_ERRORS = "SET_PATIENTTESTRESULTHISTORY_VALIDATION_ERRORS";

export const FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST = "FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST";
export const FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS = "FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS";
export const FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE = "FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE";
export const CLEAR_EXISTING_NEW_ORDER_PATIENT_CAPACITIES = "CLEAR_EXISTING_NEW_ORDER_PATIENT";

export const FETCH_PATIENTTESTRESULTHISTORY_REQUEST = "FETCH_PATIENTTESTRESULTHISTORY_REQUEST";
export const FETCH_PATIENTTESTRESULTHISTORY_SUCCESS = "FETCH_PATIENTTESTRESULTHISTORY_SUCCESS";
export const FETCH_PATIENTTESTRESULTHISTORY_FAILURE = "FETCH_PATIENTTESTRESULTHISTORY_FAILURE";

export const UPDATE_PATIENTTESTRESULTHISTORY_REQUEST = "UPDATE_PATIENTTESTRESULTHISTORY_REQUEST";
export const UPDATE_PATIENTTESTRESULTHISTORY_SUCCESS = "UPDATE_PATIENTTESTRESULTHISTORY_SUCCESS";
export const UPDATE_PATIENTTESTRESULTHISTORY_FAILURE = "UPDATE_PATIENTTESTRESULTHISTORY_FAILURE";

export const DELETE_PATIENTTESTRESULTHISTORY_REQUEST = "DELETE_PATIENTTESTRESULTHISTORY_REQUEST";
export const DELETE_PATIENTTESTRESULTHISTORY_SUCCESS = "DELETE_PATIENTTESTRESULTHISTORY_SUCCESS";
export const DELETE_PATIENTTESTRESULTHISTORY_FAILURE = "DELETE_PATIENTTESTRESULTHISTORY_FAILURE";

export const CREATE_PATIENTTESTRESULTHISTORY_REQUEST = "CREATE_PATIENTTESTRESULTHISTORY_REQUEST";
export const CREATE_PATIENTTESTRESULTHISTORY_SUCCESS = "CREATE_PATIENTTESTRESULTHISTORY_SUCCESS";
export const CREATE_PATIENTTESTRESULTHISTORY_FAILURE = "CREATE_PATIENTTESTRESULTHISTORY_FAILURE";

export const SHOW_NEW_ORDER_NEW_FORM = "SHOW_NEW_ORDER_NEW_FORM";
export const SHOW_NEW_ORDER_SEARCH_FORM = "SHOW_NEW_ORDER_SEARCH_FORM";
export const SHOW_NEW_ORDER_EDIT_FORM = "SHOW_NEW_ORDER_EDIT_FORM";
export const SET_NEW_ORDER_VALIDATION_ERRORS = "SET_NEW_ORDER_VALIDATION_ERRORS";
export const UPDATE_GRAPH_DATA = "UPDATE_GRAPH_DATA";