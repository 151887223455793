import{
    FETCH_PENDINGRESULTS_REQUEST,
    FETCH_PENDINGRESULTS_SUCCESS,
    FETCH_PENDINGRESULTS_FAILURE
   } from "constants/IncompleteResults"
import { changeDateToHumanReadableFormat } from "utils/DateTimeUtil"

const initState = {
     list : [],
     loading : false,
     errorMessage : ""
}

const PendingResults = (state = initState, action) =>{
    switch(action.type)
    {
       case FETCH_PENDINGRESULTS_REQUEST:
        return {
            ...state,
            loading : true
        } 
       case FETCH_PENDINGRESULTS_SUCCESS : 
        return {
             ...state, 
             loading : false,
             list : changeDateToHumanReadableFormat(action.payload)
        }
        case FETCH_PENDINGRESULTS_FAILURE :
        return {
            ...state,
            loading : false,
            errorMessage : action.payload
        }
        default : 
            return state
    }
}

export default PendingResults;