import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import {
  FETCH_PATIENTTESTRESULTHISTORY_REQUEST,
  FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
} from "constants/PatientTestResultHistory";
import {
  fetchPatientTestResultHistoryFailure,
  fetchPatientTestResultHistorySuccess,
  fetchPatientFailure,
  fetchPatientSuccess
  // fetchPatientTestResultHistorySuccess,
  // fetchPatientTestResultHistoryFailure,
} from "redux/actions";

import PatientTestResultHistoryService from "services/PatientTestResultHistoryService";

export function* onFetchPatientTestResultHistory() {
  yield takeLatest(FETCH_PATIENTTESTRESULTHISTORY_REQUEST, function* (payload) {
    try {
      const result = yield call(PatientTestResultHistoryService.index, payload.payload);
      if (result.status) {
        yield put(fetchPatientTestResultHistorySuccess(result.data));
      } else {
        yield put(fetchPatientTestResultHistoryFailure(result.message));
      }
    } catch (error) {
      yield put(fetchPatientTestResultHistoryFailure(error.message));
    }
  });
}

export function* onFetchExistingPatients() {
  yield takeLatest(
    FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
    function* ({ payload }) {
      try {
        const result = yield call(PatientTestResultHistoryService.searchpatient, payload);
        if (result.status & (result.data.length < 1)) {
          message.warning("Not found");
          yield put(fetchPatientSuccess(result.message));
        }
        if (result.status & (result.data.length > 0)) {
          yield put(fetchPatientSuccess(result.data));
        } else {
          yield put(fetchPatientFailure(result.message));
        }
      } catch (error) {
        yield put(fetchPatientFailure(error.message));
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(onFetchPatientTestResultHistory),
    fork(onFetchExistingPatients),
  ]);
}
