import{
    FETCH_APPROVED_ORDER_FAILURE,
    FETCH_APPROVED_ORDER_REQUEST,
    FETCH_APPROVED_ORDER_SUCCESS,
    FETCH_APPROVED_ORDER_OBX_REQUEST,
    FETCH_APPROVED_ORDER_OBX_SUCCESS,
    FETCH_APPROVED_ORDER_OBX_FAILURE,
    UPDATE_APPROVED_ORDER_REQUEST,
    UPDATE_APPROVED_ORDER_FAILURE,
    UPDATE_APPROVED_ORDER_SUCCESS,
    SHOW_APPROVED_ORDER_OBX_DIALOG
    } from "constants/OrderStatus/ApprovedOrders"
import { changeFormatedDatetoHumanReadableFormat } from "utils/DateTimeUtil"
import { updateOrder } from "utils/Order"
const initState = {
     loading : false,
     loadingObx : false,
     list : [],
     obxList : [],
     errorMessage : "",
     showDialog : false,
     
}
const ApprovedOrders = (state = initState, action)=>{
     switch(action.type)
     {
         case  FETCH_APPROVED_ORDER_REQUEST : 
            return { 
            ...state,
            loading : true,
         }
         
         case FETCH_APPROVED_ORDER_SUCCESS : 
          return {
             ...state,
             loading : false,
             list :changeFormatedDatetoHumanReadableFormat(action.payload)
         }
         
         case FETCH_APPROVED_ORDER_FAILURE : 
            return {
               ...state,
               loading : false,
               errorMessage : action.payload
            }
         
         case  FETCH_APPROVED_ORDER_OBX_REQUEST : 
         return { 
            ...state,
            loadingObx : true,
         }
          
         case FETCH_APPROVED_ORDER_OBX_SUCCESS : 
          return {
            ...state,
            loadingObx : false,
            obxList :action.payload,
         }
         
         case FETCH_APPROVED_ORDER_OBX_FAILURE : 
            return {
            ...state,
            loadingObx : false,
            errorMessage : action.payload
         }

         case UPDATE_APPROVED_ORDER_REQUEST : 
            return { 
            ...state,
         }
         case UPDATE_APPROVED_ORDER_SUCCESS : 
            return { 
             ...state,
             showDialog : false,
            //  list : updateOrder(state, action)
         }
       
         case UPDATE_APPROVED_ORDER_FAILURE : 
            return { 
            ...state,
            showDialog : false,
            errorMessage : action.payload
         }

         case SHOW_APPROVED_ORDER_OBX_DIALOG : 
         return {
             ...state, 
             showDialog : action.payload
         }
         
         default : 
            return { 
            state
         }
     }
}

export default ApprovedOrders