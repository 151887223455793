import{
    FETCH_FAULT_RESULT_FAILURE,
    FETCH_FAULT_RESULT_SUCCESS,
    FETCH_FAULT_RESULT_REQUEST
   } from "constants/FaultResults"

const initState = {
     list : [],
     loading : false,
     errorMessage : ""
}

const FaultResults = (state = initState, action) =>{
    switch(action.type)
    {
       case FETCH_FAULT_RESULT_REQUEST:
        return {
            ...state,
            loading : true
        } 
       case FETCH_FAULT_RESULT_SUCCESS : 
        return {
             ...state, 
             loading : false,
             list : action.payload
        }
        case FETCH_FAULT_RESULT_FAILURE :
        return {
            ...state,
            loading : false,
            errorMessage : action.payload
        }
        default : 
            return state
    }
}

export default FaultResults;