import {
    FETCH_REFERABLE_ORDERS_FAILURE, 
    FETCH_REFERABLE_ORDERS_REQUEST,
    FETCH_REFERABLE_ORDERS_SUCCESS
} from "constants/ReferableOrders"

const initState = {
     list : [],
     loading : false,
     errorMessage : ""
}

const ReferableOrders = (state = initState, action) =>{
    switch(action.type)
    {
       case FETCH_REFERABLE_ORDERS_REQUEST:
        return {
            ...state,
            loading : true
        } 
       case FETCH_REFERABLE_ORDERS_SUCCESS : 
        return {
             ...state, 
             loading : false,
             list : action.payload
             //list : changeDateToHumanReadableFormat(action.payload)
        }
        case FETCH_REFERABLE_ORDERS_FAILURE :
        return {
            ...state,
            loading : false,
            errorMessage : action.payload
        }
        default : 
            return state
    }
}

export default ReferableOrders;