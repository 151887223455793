import {
  FETCH_STAT_ORDERS_FAILURE,
  FETCH_STAT_ORDERS_REQUEST,
  FETCH_STAT_ORDERS_SUCCESS,
  FETCH_STAT_ORDER_OBX_REQUEST,
  FETCH_STAT_ORDER_OBX_SUCCESS,
  FETCH_STAT_ORDER_OBX_FAILURE,
  UPDATE_STAT_ORDER_REQUEST,
  UPDATE_STAT_ORDER_SUCCESS,
  UPDATE_STAT_ORDER_FAILURE,
  SHOW_STAT_ORDER_OBX_DIALOG,
} from "constants/StatOrders";

export const fetchStatOrdersRequest = (startDate, endDate, agency) => {
  return {
    type: FETCH_STAT_ORDERS_REQUEST,
    payload: {
      startDate,
      endDate,
      agency,
    },
  };
};

export const fetchStatOrdersSuccess = (listOfStatOrders) => {
  return {
    type: FETCH_STAT_ORDERS_SUCCESS,
    payload: listOfStatOrders,
  };
};

export const fetchStatOrdersFailure = (payload) => {
  return {
    type: FETCH_STAT_ORDERS_FAILURE,
    payload: payload,
  };
};

export const fetchStatOrdersObxRequest = (visitUuid) => {
  return {
    type: FETCH_STAT_ORDER_OBX_REQUEST,
    payload: {
      visitUuid,
    },
  };
};

export const fetchStatOrdersObxSuccess = (payload) => {
  return {
    type: FETCH_STAT_ORDER_OBX_SUCCESS,
    payload: payload,
  };
};

export const fetchStatOrdersObxFailure = (payload) => {
  return {
    type: FETCH_STAT_ORDER_OBX_FAILURE,
    payload: payload,
  };
};

export const updateStatOrdersRequest = (payload) => {
  return {
    type: UPDATE_STAT_ORDER_REQUEST,
    payload: payload,
  };
};

export const updateStatOrdersSuccess = (payload) => {
  return {
    type: UPDATE_STAT_ORDER_SUCCESS,
    payload: payload,
  };
};

export const updateStatOrdersFailure = (payload) => {
  return {
    type: UPDATE_STAT_ORDER_FAILURE,
    payload: payload,
  };
};

export const showStatOrdersObxDialog = (payload) => {
  return {
    type: SHOW_STAT_ORDER_OBX_DIALOG,
    payload: payload,
  };
};
