import fetch from "auth/FetchInterceptor";

const IclPayService = {};

IclPayService.pay = function (payload) {
  return fetch({
    url:"/icl-pay",
    method: "post",
    data : payload
  });
};

export default IclPayService;
