import { 
FETCH_MICRO_BIOLOGY_RESULTS_REQUEST, 
FETCH_MICRO_BIOLOGY_RESULTS_FAILURE,
FETCH_MICRO_BIOLOGY_RESULTS_SUCCESS } from "constants/MicroBiology";

export const fetchMicroBiologyResultsRequest = (startDate, endDate, agency, department)=>{
      return {
          type : FETCH_MICRO_BIOLOGY_RESULTS_REQUEST,
          payload : {
            startDate, 
            endDate,
            agency,
            department
          }
      }
}

export const fetchMicroBiologyResultsSuccess = (payload)=>{
    return {
        type : FETCH_MICRO_BIOLOGY_RESULTS_SUCCESS,
        payload : payload
    }
}

export const fetchMicroBiologyResultsFailure = ()=>{
    return {
        type : FETCH_MICRO_BIOLOGY_RESULTS_FAILURE,
    }
}