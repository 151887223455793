import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_COUPON_REQUEST,
  DELETE_COUPON_REQUEST,
  FETCH_COUPONS_REQUEST,
  PRINT_COUPONS_REQUEST,
  USE_COUPON_REQUEST,
} from "constants/Coupon";
import {
  createCouponFailure,
  createCouponSuccess,
  deleteCouponFailure,
  deleteCouponSuccess,
  fetchCouponsFailure,
  fetchCouponsSuccess,
  printCouponFailure,
  printCouponSuccess,
  setCouponValidationErrors,
  useCouponFailure,
  useCouponSuccess,
} from "redux/actions";
import CouponService from "services/Coupon";

export function* onFetchCoupons() {
  yield takeLatest(FETCH_COUPONS_REQUEST, function* () {
    try {
      const result = yield call(CouponService.index);
      if (result.status) {
        yield put(fetchCouponsSuccess(result.data));
      } else {
        yield put(fetchCouponsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchCouponsFailure(error.message));
    }
  });
}

export function* onDeleteCoupon() {
  yield takeLatest(DELETE_COUPON_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CouponService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteCouponSuccess(result.data));
      } else {
        message.error("Something went wrong");
        yield put(deleteCouponFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteCouponFailure(error.message));
    }
  });
}

export function* onCreateCoupon() {
  yield takeLatest(CREATE_COUPON_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CouponService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createCouponSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Please Check your inputs");
        yield put(setCouponValidationErrors(result.data.errors));
        yield put(createCouponFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createCouponFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteCouponFailure(error.message));
    }
  });
}

export function* onPrintCoupons() {
  yield takeLatest(PRINT_COUPONS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CouponService.print, payload);
      if (result.status) {
        message.success("Printed");
        yield put(printCouponSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Please check your inputs");
        yield put(printCouponFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(printCouponFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(printCouponFailure(error.message));
    }
  });
}

export function* onUseCoupons() {
  yield takeLatest(USE_COUPON_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CouponService.use, payload);
      if (result.status) {
        message.success("Used");
        yield put(useCouponSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(useCouponFailure(result.message));
      } else {
        yield put(useCouponFailure(result.message));
      }
    } catch (error) {
      yield put(useCouponFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onCreateCoupon),
    fork(onFetchCoupons),
    fork(onPrintCoupons),
    fork(onUseCoupons),
    fork(onDeleteCoupon),
  ]);
}
